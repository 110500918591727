<template lang="pug">
    div
        v-dialog(persistent lazy max-width="600px" v-model="form")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="form=false")
                            v-icon close
                    h3.full-width {{ $t('Datos del usuario') }} {{ current.names }}
                v-card-text.pt-2
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        h3.my-2 {{ $t('Datos de formulario personalizado')}}
                        v-data-table(
                            :items="values"
                            ref="table"
                            :headers="headers"
                            hide-actions
                        )
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                            template(slot='items' slot-scope='props')
                                tr
                                    td {{ props.item.label }}
                                    td(v-html="props.item.value")
                        h3.my-2 {{ $t('Datos de CRM')}}
                        v-data-table(
                            :items="crm_values"
                            ref="crm_table"
                            :headers="headersCrm"
                            hide-actions
                        )
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                            template(slot='items' slot-scope='props')
                                tr
                                    td {{ props.item.label }}
                                    td(v-html="props.item.value")
                                    td
                                        h5.my-2(v-if="!Array.isArray(props.item.author) && Object.keys(props.item.author).length")
                                            v-icon.cur-p.primary--text.mr-2(@click="$refs.editForm.open(setEditFormValues(props.item))" small v-if="$root.profile.uuid == props.item.authorid") edit
                                            | {{ $t('Creado por') }} 
                                            v-avatar.mr-2(:size="20")
                                                img(v-if="photoUrl(props.item.author.photo).length" :src="photoUrl(props.item.author.photo)")
                                                v-gravatar(:email="props.item.author.email")
                                            | {{ props.item.author.names || props.item.author.name }}
                                        small.d-block.mb-3
                                            | {{ $t('Fecha de creación') }}: {{ props.item.date | moment('YYYY-MM-DD') }}
                                        
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="form=false") {{$t('Aceptar')}}
        edit-form(ref="editForm" @success="reFetchForm")
</template>

<script>

import config from 'src/config'
import EditForm from 'components/crm/EditForm'
export default {
    props:{
        userdata: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            form: false,
            current: {},
            values: [],
            crm_values: [],
            headers: [
                {
                    text  : 'Campo',
                    value : 'label',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Valor',
                    value : 'value',
                    sortable: false,
                    align: 'left'
                }
            ],
            preload: false
        }
    },
    computed: {
        headersCrm() {

            let headers = [
                {
                    text  : 'Campo',
                    value : 'label',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Valor',
                    value : 'value',
                    sortable: false,
                    align: 'left'
                }
            ]

            headers.push({
                text  : 'Detalles',
                value : 'detail',
                sortable: false,
                align: 'left'
            })

            return headers
        }
    },
    methods: {
        photoUrl(photo){
            if(photo != null && photo.length){
                return config.uploaduser + photo
            }
            return ''
        },
        async open(item){

            this.values = []
            this.crm_values = []

            this.form = true
            this.current = this._.cloneDeep(item)
            this.preload = true
            await this.fetchForm()
            this.preload = false
        },
        fetchForm() {

            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/network/form/user', {
                        params: {
                            networkid : this.current.networkid,
                            userid    : this.current.uuid,
                            userdata  : this.userdata ? 1 : 0
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {
                            
                            let customForm = data.data
                            this.values = customForm.custom
                            this.crm_values = customForm.crm
                        }
                        resolve()
                    }).catch(() => resolve())
                })
            })
        },
        async reFetchForm() {
            this.preload = true
            await this.fetchForm()
            this.preload = false 
        },
        setEditFormValues(item) {
            return {
                uuid     : item.uuid,
                networkid: this.current.networkid,
                values   : item.original_value,
                userid   : this.current.uuid
            }
        }
    },
    components: {
        EditForm
    }
}
</script>