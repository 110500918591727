<template lang="pug">
    div
        template(v-if="colors.length")
            v-divider.primary.my-2
            h3.mb-1.mt-3 {{ $t('Resumen de posiciones') }}
            .table__overflow
                table.datatable.table
                    thead
                        tr
                            th(align="left" :style="i == 0 ? 'padding: 0px 5px; margin:0px;' : ''" v-for="(header, i) in ['Color', 'Condición', 'Total']") 
                                span.subheading {{ $t(header) }}
                    tbody
                        tr.color--drag(v-for="(color, i) in colors")
                            td(align="left" style="padding: 0px 5px; margin:0px;")
                                div(:style="'background:' + color.hex + ';width:20px;height:20px;border-radius:30px;display:inline-block;vertical-align-middle;border:thin solid rgb(193 193 193);'")
                            td(align="left")
                                template(v-if="type == 1")
                                    template(v-if="!color.hasOwnProperty('other')") {{ color.model }}
                                    template(v-else) {{ $t('Otros') }}
                                template(v-else-if="type == 2")
                                    template(v-if="!color.hasOwnProperty('other')") {{ color.model }}
                                    template(v-else) {{ $t('Otros') }}
                                template(v-else-if="type == 3")
                                    template(v-if="!color.hasOwnProperty('other')") {{ color.model.gender.text }}
                                    template(v-else) {{ $t('Otros') }}
                                template(v-else-if="type == 4")
                                    template(v-if="!color.hasOwnProperty('other')") {{ color.label }}
                                    template(v-else) {{ $t('Otros') }}
                                template(v-else-if="type == 5")
                                    template(v-if="!color.hasOwnProperty('other')"): small {{ color.model }}
                                    template(v-else) {{ $t('Otros') }}
                                template(v-else-if="type == 6")
                                    template(v-if="!color.hasOwnProperty('other')"): small
                                        template(v-for="cond in color.model")
                                            .d-block.mt-2 {{ cond.form }}
                                            .d-block.mb-2 {{ cond.option }}
                                    template(v-else) {{ $t('Otros') }}
                                // Niveles
                                template(v-else-if="type == 7")
                                    template(v-if="!color.hasOwnProperty('other')") {{ color.label }}
                                    template(v-else) {{ $t('Otros niveles') }}
                                template(v-else)
                                    | {{ $t('Mayor o igual a') }} {{ color.gt }}
                            td(align="left") {{ color.total }}
</template>

<script>
    export default {
        props: {
            colors: {
                type: Array,
                default: []
            },
            type: {
                type: Number,
                default: 0
            }
        }
    }
</script>