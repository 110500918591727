<template lang="pug">
    div
        v-dialog(lazy max-width="600px" v-model="total")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="total=false")
                            v-icon close
                    h3.full-width {{$t('Total de usuarios invitados')}}
                    h5.full-width
                        v-avatar.mr-3(:size="48")
                            img(v-if="photoUrl(genesisNode.photo).length" :src="photoUrl(genesisNode.photo)")
                            v-gravatar(v-else-if="Object.keys(genesisNode).length" :email="genesisNode.email")
                        | {{ genesisNode.names || genesisNode.name}} - {{genesisNode.phone}}
                v-card-text.pt-2
                    v-layout.mt-3.mb-2(row wrap)
                        v-flex(xs12 sm6)
                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                    v-spacer
                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                        v-flex(xs12 sm6)
                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                    v-spacer
                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                        v-flex.text-xs-center(xs12 v-if="loadTotal")
                            v-progress-circular(indeterminate color="primary")
                        v-flex(xs12 v-else)
                            canvas(id="total-chart" height="200px")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="total=false") {{$t('Aceptar')}}
</template>

<script>

import config from 'src/config'
export default {
    data(){
        return {
            total: false,
            loadTotal: false,
            genesisNode: {},
            currentNode: {},
            genesis: true,
            modalDateStart: false,
            modalDateEnd: false,
            filterSchema: {
                from : null,
                to   : null
            },
            filter: {}
        }
    },
    watch: {
        filter: {
            deep: true,
            handler(){
                this.fetchDetailTotal(this.genesisNode.uuid)
            }
        },
    },
    methods:{

        photoUrl(photo){
            if(photo != null && photo.length){
                return config.uploaduser + photo
            }
            return ''
        },

        open(item){

            this.total = true

            this.genesisNode = this._.cloneDeep(item)
            this.currentNode = this._.cloneDeep(item)
            this.filter = this._.cloneDeep(this.filterSchema)

            this.filter.from = this.$moment().subtract(1, 'month').format('YYYY-MM-DD')
            this.filter.to = this.$moment().format('YYYY-MM-DD')
        },

        makeChart(items){

            this.$nextTick(() => {

                let ctx = document.getElementById('total-chart').getContext('2d')
                let chart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: items.map((item) => item.label),
                        datasets: [
                            {
                                label      : this.$t('Total de referidos'),
                                borderColor: '#169cb3',
                                backgroundColor: '#169cb3',
                                data       : items.map(item => item.total),
                                fill       : false,
                                lineTension: 0,
                                pointBackgroundColor: '#169cb3',
                                borderWidth: 1,
                                pointBorderWidth: 0,
                                pointRadius: 2,
                                pointHoverRadius: 3
                            },
                            {
                                label      : this.$t('Total de referidos directos'),
                                borderColor: '#000000',
                                backgroundColor: '#000000',
                                data       : items.map(item => item.totalshare),
                                fill       : false,
                                lineTension: 0,
                                pointBackgroundColor: '#000000',
                                borderWidth: 1,
                                pointBorderWidth: 0,
                                pointRadius: 2,
                                pointHoverRadius: 3
                            }
                        ]
                    }
                })
            })
        },

        fetchDetailTotal(uuid){

            this.loadTotal = true
            this.$api(this, (xhr) => {
                xhr.get('/report/network/graph/detail-total', {
                    params: {
                        uuid: uuid,
                        from: this.filter.from,
                        to  : this.filter.to
                    }
                }).then((r) => {

                    let data = r.data
                    this.loadTotal = false

                    if(data.response){
                        let chartData = data.data.dates
                        this.makeChart(chartData)
                    }

                }).catch(() => {
                    this.loadTotal = false
                })
            })
        },
    }
}

</script>