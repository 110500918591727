module.exports = {
    title           : 'Wappid',
    apiurl          : 'https://api.wappid.com/',
    url             : 'https://wappid.com/',
    imgurl          : 'https://wappid.com/public/img/logo.png',
    homeurl         : 'https://wappid.com/public/img/logo.png',
    uploadurl       : 'https://api.wappid.com/photo/',
    uploadimg       : 'https://api.wappid.com/image/',
    uploaduser      : 'https://api.wappid.com/avatar/',
    uploadbanner    : 'https://api.wappid.com/bns/',
    galleryurl      : 'https://wappid.com/gallery/',
    networkText     : 'Te invitamos a unas a este sistema.',
    xtoken          : 'X-Token-Wappid',
    data            : {},
    lang            : 'es',
    languages       : ['es', 'pt', 'en', 'it', 'fr', 'de'],
    currentLanguage : 'es',
    fallbackLanguage: 'es',
    flanguages      : [],
    ws_host         : 'ws.wappid.com',
    ws_port         : 443,
    ws_secure       : true,
    mapsKey         : 'AIzaSyBDeplruls2YWcm8sD8CAlEkdNFOVmQl0U',
    ws_whatsapp_url : 'wss://wswp.wappid.com/whatsapp/',
    captchaKey      : '6LcZb_coAAAAALkR2FNJkMHVHyGp2XFg4jxJNZEN'
}