import socketClusterClient from 'socketcluster-client/socketcluster-client'
import config from 'src/config'

export default {

    install(Vue) {

        if (!Vue.prototype.hasOwnProperty('$ws')) {
        
            let ws = socketClusterClient.create({
                autoConnect: false,
                hostname   : config.ws_host,
                port       : config.ws_port,
                secure     : config.ws_secure
            })

            Object.defineProperty(Vue.prototype, '$ws', {
                value: ws
            })
        }
    }
}