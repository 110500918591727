<template lang="pug">
div
    v-snackbar(
    :timeout="3000"
    :top="true"
    :right="true"
    :color="formColor"
    v-model="formMsj")
        | {{ formText }}
        v-btn(flat dark @click.native="formMsj = false") {{ $t('cerrar') }}
    v-dialog(lazy max-width="1024px" v-model="form")
        v-card
            v-card-title 
                .btns.btns-right
                    v-btn(icon color="secondary" small @click.native="form=false")
                        v-icon close
                h3.full-width {{ $t('Red') }} {{ current.name }}
            v-card-text.pt-2
                template(v-if="preload")
                    h3 {{$t('Cargando')}}...
                template(v-else)
                    h3 {{ $t('Campos visibles') }}
                    v-data-table(
                        :items="rows"
                        :headers="headers"
                        hide-actions
                    )
                        template(slot="no-results")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot="no-data")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot='headers' slot-scope='props')
                            tr
                                th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                    span.subheading {{ $t(header.text) }}
                        template(slot='items' slot-scope='props')
                            tr
                                td 
                                    span {{ $t(props.item.label) }}
                                    small.d-block.red--text(v-if="props.item.depends.length") {{ $t('Depende de:') }}&nbsp;{{ labelDepends(props.item.depends) }}
                                td
                                    .switch-group
                                        label {{ $t('No') }}
                                        v-switch(:label="$t('Si')" color="success" v-model="props.item.enable")
                                td
                                    .switch-group(v-if="props.item.tag == 'zones'")
                                        label {{ $t('No') }}
                                        v-switch(:label="$t('Si')" color="success" v-model="props.item.required")
                    v-divider.my-2
                    template(v-if="current.isRodolfista")
                        h3 {{ $t('Campos visibles (Rodolfistas)') }}
                        v-data-table(
                            :items="rrows"
                            :headers="rheaders"
                            hide-actions
                        )
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                            template(slot='items' slot-scope='props')
                                tr
                                    td 
                                        span {{ $t(props.item.label) }}
                                        small.d-block.red--text(v-if="props.item.depends.length") {{ $t('Depende de:') }}&nbsp;{{ labelDepends(props.item.depends, 'rrows') }}
                                    td
                                        .switch-group
                                            label {{ $t('No') }}
                                            v-switch(:label="$t('Si')" color="success" v-model="props.item.enable")
                                    td
                                        .switch-group
                                            label {{ $t('No') }}
                                            v-switch(:label="$t('Si')" color="success" v-model="props.item.required")
                        v-divider.my-2
                    v-tabs(grow)
                        v-tab {{ $t('Formularios personalizados') }}
                        v-tab {{ $t('Atributos') }}
                        v-tab-item
                            v-layout.mt-2(row wrap)
                                v-flex(xs12)
                                    v-select(:label="$t('Tipo de elemento')" v-model="element" :return-object="true" :items="elements" item-text="label" item-value="type")
                                template(v-if="Object.keys(element).length")
                                    v-flex(xs12)
                                        v-text-field(v-model="element.placeholder" :label="$t('Nombre del campo')")
                                    v-flex(xs12 v-if="element.type == 4")
                                        v-select(:label="$t('Depende de')" clearable v-model="element.depends" :items="listDepends(element)" item-text="placeholder" item-value="uuid" @input="updateDepends")
                                    v-flex(xs12 v-if="[2,3,4].includes(element.type)")
                                        h4 {{ $t('Opciones') }}
                                        v-layout(row wrap v-if="!hasDepends(element)")
                                            v-flex(xs12)
                                                v-select(clearable :return-object="true" item-value="uuid" item-text="name" :items="model.attribute" :label="$t('Cargar atributo')" @input="loadAttribute($event, element)")
                                            v-flex(xs12)
                                                v-text-field(multi-line v-model="option" :label="$t('Opción')")
                                            v-flex(xs12)
                                                v-btn(depressed small color="primary" @click="addOption(element)") {{ $t('Agregar opción') }}
                                            v-flex(xs12 v-if="element.options.length")
                                                draggable(tag="v-list" style="min-height:1px" v-model="element.options" :options="optionsDrag")
                                                    v-list-tile.option--drag(v-for="(option, i) in element.options" :key="'opt-'+i")
                                                        v-list-tile-avatar
                                                            v-icon.option--handle(style="cursor:move") list
                                                        v-list-tile-content
                                                            v-list-tile-title {{ typeof option == 'string' ? option : option.option }}
                                                        v-list-tile-action(@click="element.options.splice(i, 1)")
                                                            v-icon(color="primary") close
                                        v-layout(row wrap v-else v-for="(depend, i) in depends")
                                            v-flex(xs12)
                                                h4 {{ depend.option }}
                                                v-text-field(multi-line v-model="depend.depend" :label="$t('Opción')")
                                            v-flex(xs12)
                                                v-btn(depressed small color="primary" @click="addOption(element, depend, i)") {{ $t('Agregar opción') }}
                                            v-flex(xs12 v-if="Object.keys(element.options).length")
                                                draggable(tag="v-list" style="min-height:1px" v-model="element.options[depend.uuid]" :options="optionDrag(i)")
                                                    v-list-tile(:class="'option--drag-'+i" v-for="(option, j) in element.options[depend.uuid]" :key="'opt-'+i+'-'+j")
                                                        v-list-tile-avatar
                                                            v-icon(:class="'option--handle-'+i" style="cursor:move") list
                                                        v-list-tile-content
                                                            v-list-tile-title {{ option.option }}
                                                        v-list-tile-action(@click="element.options[depend.uuid].splice(j, 1)")
                                                            v-icon(color="primary") close
                                    v-flex(xs12)
                                        label {{$t('Obligatorio')}}
                                        v-divider.mt-2
                                        .switch-group
                                            label {{ $t('No') }}
                                            v-switch(:label="$t('Si')" color="success" v-model="element.required")
                                    v-flex(xs12)
                                        label {{$t('Visible para el público')}}
                                        v-divider.mt-2
                                        .switch-group
                                            label {{ $t('No') }}
                                            v-switch(:label="$t('Si')" color="success" v-model="element.visibility")
                                    template(v-if="[1,5].includes(element.type)")
                                        v-flex(xs12)
                                            label {{$t('Validar que no se repita')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{ $t('No') }}
                                                v-switch(:label="$t('Si')" color="success" v-model="element.unique")
                                        v-flex(xs12)
                                            label {{$t('Opciones de validación')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{ $t('No') }}
                                                v-switch(:label="$t('Si')" color="success" v-model="element.validation")
                                        v-flex(xs12 v-if="element.validation")
                                            v-select(:return-object="true" item-value="type" item-text="label" :items="formValidationRules" :label="$t('Opciones de validación')" @change="addValidationRule($event, element)")
                                        template(v-if="element.validation && element.rules.length")
                                            v-flex(xs12)
                                                v-data-table(
                                                    :items="element.rules"
                                                    :headers="ruleHeaders"
                                                    hide-actions
                                                )
                                                    template(slot='headers' slot-scope='props')
                                                        tr
                                                            th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                                span.subheading {{ $t(header.text) }}
                                                    template(slot='items' slot-scope='props')
                                                        tr
                                                            td 
                                                                v-text-field.mb-2(v-if="['minlength', 'maxlength'].includes(props.item.type)" min="0" type="number" :label="getValidationRuleLabel(props.item.type)" v-model="props.item.value")
                                                                span(v-if="['onlynumber', 'nowhitespaces'].includes(props.item.type)") {{ getValidationRuleLabel(props.item.type) }}
                                                            td
                                                                v-icon.cur-p(@click="deleteValidationRule(element, props.index)") delete

                                v-flex(xs12)
                                    v-btn(small depressed color="primary" @click="addElement") {{ $t(element.uuid ? 'Guardar' : 'Agregar') }}
                            v-divider.mt-2
                            h3(v-if="model.custom.length") {{ $t('Vista previa del formulario') }}
                            draggable.mt-2.row.wrap(tag="v-layout" v-model="model.custom" :options="formDrag")
                                v-flex.form--drag(xs12 v-for="(el, i) in model.custom" :key="'el-'+i")
                                    //- Acciones
                                    div.d-block
                                        v-icon.form--handle(style="cursor:move") list
                                        v-icon.cur-p(@click="editElement(i)") edit
                                        v-icon.cur-p(@click="$refs.delete.open(i)") delete
                                    //-Entrada de texto
                                    template(v-if="el.type == 1")
                                        v-text-field(:label="el.placeholder" :required="el.required")
                                    //-Entrada de párrafo
                                    template(v-if="el.type == 5")
                                        v-text-field(multi-line :label="el.placeholder" :required="el.required")
                                    //-Selección multiple
                                    template(v-if="el.type == 2")
                                        label.d-block.mt-3 {{ el.placeholder }}
                                        v-checkbox(v-for="opt in el.options" :label="(typeof opt == 'string' ? opt : opt.option)")
                                    //-Selección
                                    template(v-if="el.type == 3")
                                        v-radio-group
                                            label {{ el.placeholder }}{{el.required ? '&bull;':''}}
                                            v-radio(v-for="opt in el.options" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                                    //-Listado
                                    template(v-if="el.type == 4")
                                        v-select(clearable v-if="el.depends == null ? true : !el.depends.length" :return-object="true" :required="el.required" item-value="uuid" item-text="option" :items="el.options" :label="el.placeholder" @input="computedDepends(el, $event)")
                                        v-select(clearable v-else :items="el.hasOwnProperty('coptions') ? el.coptions : []" :required="el.required" item-value="uuid" item-text="option" :label="el.placeholder" :return-object="true" @input="computedDepends(el, $event)")
                        v-tab-item
                            v-flex(xs12)
                                v-text-field(v-model="attribute.name" :label="$t('Nombre del atributo')")
                            h4 {{ $t('Opciones') }}
                            v-layout(row wrap)
                                v-flex(xs12)
                                    v-text-field(multi-line v-model="attribute.option" :label="$t('Opción')")
                                v-flex(xs12)
                                    v-btn(depressed small color="primary" @click="addOptionAttribute(attribute)") {{ $t('Agregar opción') }}
                                v-flex(xs12 v-if="attribute.options.length")
                                    draggable(tag="v-list" style="min-height:1px" v-model="attribute.options" :options="optionsDrag")
                                        v-list-tile.option--drag(v-for="(option, i) in attribute.options" :key="'opt-'+i")
                                            v-list-tile-avatar
                                                v-icon.option--handle(style="cursor:move") list
                                            v-list-tile-content
                                                v-list-tile-title {{ typeof option == 'string' ? option : option.option }}
                                            v-list-tile-action(@click="attribute.options.splice(i, 1)")
                                                v-icon(color="primary") close
                                v-flex(xs12)
                                    v-btn(small depressed color="primary" @click="addAttribute") {{ $t(attribute.uuid ? 'Guardar' : 'Agregar') }}
                            v-divider.mt-2
                            h3(v-if="model.attribute.length") {{ $t('Listado de atributos') }}
                            v-flex.form--drag(xs12 v-for="(at, i) in model.attribute" :key="'at-'+i")
                                //- Acciones
                                div.d-block
                                    v-icon.form--handle(style="cursor:move") list
                                    v-icon.cur-p(@click="editAttribute(i)") edit
                                    v-icon.cur-p(@click="$refs.deleteAttribute.open(i)") delete
                                //-Listado
                                v-select(clearable item-value="uuid" item-text="option" :items="at.options" :label="at.name")
            v-card-actions
                .btns.btns-right
                    v-btn(color="secondary" small @click="form=false") {{$t('Aceptar')}}
                    v-btn(v-if="!preload" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
    confirm(ref="delete" @action="deleteElement" :title="$t('Eliminar campo')" :message="$t('¿Estás seguro de eliminar este campo, la información registrada previamente ya no sera visible?')")
    confirm(ref="deleteAttribute" @action="deleteAttribute" :title="$t('Eliminar atributo')" :message="$t('¿Estás seguro de eliminar este atributo, la información registrada previamente ya no sera visible?')")
</template>

<script>

import draggable from 'vuedraggable'
import Vue from 'vue'
import fn from 'mixins/fn'

export default {
    mixins: [fn],
    data(){
        return {
            form      : false,
            formMsj   : false,
            formColor : '',
            formText  : '',
            loadingBtn: false,
            limit     : this.$root.limit,
            offset    : 0,
            forms     : [],
            preload   : false,
            elements: [],
            depends: [],
            rows: [
                {
                    label   : 'País',
                    tag     : 'countryid',
                    depends : [],
                    enable  : true,
                    required: true
                },
                {
                    label   : 'Estado/Departamento/Provincia',
                    tag     : 'stateid',
                    depends : ['countryid'],
                    enable: true,
                    required: true
                },
                {
                    label   : 'Ciudad/Municipio',
                    tag     : 'cityid',
                    depends : ['stateid'],
                    enable: true,
                    required: true
                },
                {
                    label   : 'Zonas',
                    tag     : 'zones',
                    depends : ['cityid'],
                    enable: true,
                    required: true
                },
                {
                    label   : 'Género',
                    tag     : 'gender',
                    depends : [],
                    enable: true,
                    required: true
                },
                {
                    label   : 'Fecha de nacimiento',
                    tag     : 'birthdate',
                    depends : [],
                    enable: true,
                    required: true
                },
                {
                    label   : 'Documento de identificación',
                    tag     : 'document',
                    depends : [],
                    enable: true,
                    required: true
                },
            ],
            headers: [
                {
                    text  : 'Campo',
                    value : 'label',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Estado',
                    value : 'enable',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Requerido',
                    value : 'required',
                    sortable: false,
                    align: 'left'
                }
            ],
            ruleHeaders: [
                {
                    text  : 'Regla',
                    value : 'type',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Opciones',
                    value : 'action',
                    sortable: false,
                    align: 'left'
                }
            ],
            option: '',
            element: {},
            attributeSchema: {
                name: '',
                option: '',
                options: []
            },
            attribute: {},
            model: {
                custom    : [],
                attribute : []
            },
            optionsDrag: {
                group    : 'options',
                draggable: '.option--drag',
                handle   : '.option--handle'
            },
            formDrag: {
                group    : 'forms',
                draggable: '.form--drag',
                handle   : '.form--handle'
            },
            current: {},
            // Campos rodolfistas
            rrows: [
                {
                    label   : 'Departamento donde estudió',
                    tag     : 'field_state',
                    depends : [],
                    enable  : true,
                    required: false
                },
                {
                    label   : 'Ciudad donde estudió',
                    tag     : 'field_city',
                    depends : ['field_state'],
                    enable  : true,
                    required: false
                },
                {
                    label   : 'Colegio donde estudió',
                    tag     : 'field_school',
                    depends : ['field_city'],
                    enable  : true,
                    required: false
                },
                {
                    label   : 'Universidad donde estudió',
                    tag     : 'field_institution',
                    depends : [],
                    enable  : true,
                    required: false
                },
                {
                    label   : 'Grados recibidos o por recibir de pregado o postgrado',
                    tag     : 'field_grade',
                    depends : [],
                    enable  : true,
                    required: false
                },
                {
                    label   : 'Área de estudio',
                    tag     : 'field_area',
                    depends : [],
                    enable  : true,
                    required: false
                },
                {
                    label   : 'Otra área de estudio',
                    tag     : 'field_other_area',
                    depends : [],
                    enable  : true,
                    required: false
                }
            ],
            rheaders: [
                {
                    text  : 'Campo',
                    value : 'label',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Estado',
                    value : 'enable',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Requerido',
                    value : 'required',
                    sortable: false,
                    align: 'left'
                }
            ],
        }
    },
    computed: {
        profile(){
            return this.$root.profile
        },
        elementSchema() {

            let textItems = {
                unique: false,
                validation: false,
                rules: []
            }

            return [
                {
                    type       : 1,
                    label      : this.$t('Entrada de texto'),
                    placeholder: '',
                    required   : false,
                    visibility : true,
                    ...textItems
                },
                {
                    type       : 5,
                    label      : this.$t('Entrada de párrafo'),
                    placeholder: '',
                    required   : false,
                    visibility : true,
                    ...textItems
                },
                {
                    type       : 2,
                    label      : this.$t('Selección multiple'),
                    placeholder: '',
                    required   : false,
                    options    : [],
                    visibility : true,
                    ...textItems
                },
                {
                    type       : 3,
                    label      : this.$t('Selección'),
                    placeholder: '',
                    required   : false,
                    options    : [],
                    visibility : true,
                    ...textItems
                },
                {
                    type       : 4,
                    label      : this.$t('Listado'),
                    placeholder: '',
                    required   : false,
                    options    : [],
                    depends    : '',
                    visibility : true,
                    ...textItems
                }
            ]
        }
    },
    created(){
        this.elements = this._.cloneDeep(this.elementSchema)
        this.attribute = this._.cloneDeep(this.attributeSchema)
    },
    methods:{

        async open(item){

            this.form = true
            this.preload = true
            this.elements = this._.cloneDeep(this.elementSchema)
            this.current = this._.cloneDeep(item)
            
            await this.fetch()
            this.preload = false
        },
        addElement(){

            try{

                if(!Object.keys(this.element).length){
                    throw "Seleccione un tipo de elemento"
                }

                if(!this.element.placeholder.trim().length){
                    throw "Inserte el nombre del campo"
                }

                if(this.element.type != 1 && this.element.type != 5){
                    
                    try{

                        if(this.element.hasOwnProperty('depends')){
                            
                            if((this.element.depends == null || !this.element.depends.length) && !Object.keys(this.element.options).length){
                                throw false
                            }

                            if(!Object.keys(this.element.options).length){
                                throw false
                            }

                        }else{
                            if(Array.isArray(this.element.options) ? !this.element.options.length : !Object.keys(this.element.options).length){
                                throw false
                            }
                        }

                    }catch(e){
                        throw "Seleccione al menos una opción"
                    }
                }

                if(this.element.hasOwnProperty('uuid') && this.model.custom.some((f) => f.uuid == this.element.uuid)){

                    let i = this.model.custom.findIndex((f) => f.uuid == this.element.uuid)
                    this.model.custom.splice(i, 1, this.element)
                }else{
                    this.element.uuid = this.$uuid()
                    this.model.custom.push(this.element)
                }

                this.elements = this._.cloneDeep(this.elementSchema)
                this.element  = {}

            }catch(e){
                this.formMsj = true
                this.formColor = 'error'
                this.formText = this.$t(e)
            }
        },
        addOption(element, depend={}, index=0){

            let isDepend = Object.keys(depend).length ? true : false
            if(isDepend ? !depend.depend.trim().length : !this.option.trim().length){
                
                this.formMsj = true
                this.formColor = 'error'
                this.formText = this.$t('Inserte una opción')
                return
            }

            if(isDepend){

                let isParent = this.element.options.hasOwnProperty(depend.uuid)
                let option   = {
                    uuid  : this.$uuid(),
                    parent: depend.uuid,
                    option: depend.depend,
                    depend: ''
                }

                if(isParent){
                    this.element.options[depend.uuid].push(option) 
                }else{

                    if(Array.isArray(this.element.options)){
                        this.element.options = {
                            [depend.uuid]: [option]
                        }
                    }else{
                        Vue.set(this.element.options, depend.uuid, [option])
                    }
                }
                depend.depend = ''

            }else{
                this.element.options.push({
                    uuid  : this.$uuid(),
                    option: this.option,
                    depend: ''
                })
                this.option = ''
            }
        },
        deleteElement(i){
            this.model.custom.splice(i, 1)
            this.$refs.delete.close()
        },
        editElement(i){
            this.element = this._.cloneDeep(this.model.custom[i])
            this.updateDepends()
        },
        addOptionAttribute(attribute){
            this.attribute.options.push({
                uuid  : this.$uuid(),
                option: this.attribute.option
            })
            this.attribute.option = ''
        },
        addAttribute(){
            try{

                if(!this.attribute.name.trim().length){
                    throw "Inserte el nombre del atributo"
                }

                if(!this.attribute.options.length){
                    throw "Seleccione al menos una opción"
                }

                this.attribute.option = ''
                if(this.attribute.hasOwnProperty('uuid') && this.model.attribute.some((f) => f.uuid == this.attribute.uuid)){
                    let i = this.model.attribute.findIndex((f) => f.uuid == this.attribute.uuid)
                    this.model.attribute.splice(i, 1, this.attribute)
                }else{
                    this.attribute.uuid = this.$uuid()
                    this.model.attribute.push(this.attribute)
                }

                this.attribute = this._.cloneDeep(this.attributeSchema)

            }catch(e){
                this.formMsj = true
                this.formColor = 'error'
                this.formText = this.$t(e)
            }
        },
        deleteAttribute(i){
            this.model.attribute.splice(i, 1)
            this.$refs.delete.close()
        },
        editAttribute(i){
            this.attribute = this._.cloneDeep(this.model.attribute[i])
        },
        save(){

            let post = this._.cloneDeep(this.model)
            post.custom = JSON.stringify(post.custom.map((f) => {
                f.required   = f.required ? 1 : 0
                f.visibility = f.visibility ? 1 : 0
                f.unique     = f.unique ? 1 : 0
                f.validation = f.validation ? 1 : 0
                return f
            }))

            post.available = this.rows.map((r) => {
                return {
                    enable: r.enable ? 1 : 0,
                    required: r.required ? 1 : 0,
                    tag     : r.tag
                }
            })

            if(this.current.isRodolfista){
                post.available = post.available.concat(this.rrows.map((r) => {
                    return {
                        enable  : r.enable ? 1  : 0,
                        required: r.required ? 1: 0,
                        tag     : r.tag
                    }
                }))
            }

            post.available = JSON.stringify(post.available)
            post.attribute = JSON.stringify(post.attribute)
            post.networkid = this.current.uuid

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/network/form', this.$qs.stringify(post)).then(async (r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.formMsj = true

                    if(data.response){
                        this.formColor = 'success'
                        this.formText = data.message
                        this.preload = true
                        await this.fetch()
                        this.preload = false
                    }else{
                        this.formColor = 'error'
                        this.formText = data.message
                    }
                }).catch(() => {})
            })
        },
        fetch(){

            this.element = {}
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: { networkid: this.current.uuid, include_crm: 1 }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let model = data.data
                            model.custom = model.custom.map((c) => {
                                c.required   = c.required == 1 ? true : false
                                c.visibility = c.visibility == 1 ? true : false
                                c.unique     = c.unique == 1 ? true : false
                                c.validation = c.validation == 1 ? true : false
                                c.options    = this.repairOptions(c.options)
                                c.rules      = !c.rules ? [] : c.rules
                                return c
                            })

                            if(model.available != null && model.available.length){
                                
                                for(let av of model.available){
                                    
                                    let i = this.rows.findIndex((r) => r.tag == av.tag)

                                    if(i != -1){

                                        let row = this.rows[i]
                                        row.enable = av.enable == 1 ? true : false
                                        row.required = av.required == 1 ? true : false
                                        this.rows.splice(i, 1, row)
                                    }
                                }

                                if(this.current.isRodolfista){

                                    for(let av of model.available){

                                        let i = this.rrows.findIndex((r) => r.tag == av.tag)

                                        if(i != -1){

                                            let row = this.rrows[i]
                                            row.enable   = av.enable == 1 ? true : false
                                            row.required = av.required == 1 ? true : false

                                            this.rrows.splice(i, 1, row)
                                        }
                                    }
                                }
                            }
                            
                            this.model = this._.cloneDeep(model)
                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        labelDepends(depends, key='rows'){
            depends = depends.map((tag) => {
                let label = this[key].find((t) => t.tag == tag)
                return this.$t(label.label)
            })

            return depends.join(', ')
        },
        listDepends(element){
            let list = this.model.custom.filter((m) => m.type == 4)
            return list.map((l) => {
                return {
                    uuid        : l.uuid,
                    placeholder : l.placeholder,
                    options  : l.options,
                    optionsv : (new Array(l.options.length)).fill('')
                }
            })
        },
        updateDepends(){
            
            let depends = this.model.custom.find((c) => c.uuid == this.element.depends)
            let dData = depends ? depends.options : []
            let dependsData = this._.cloneDeep(dData)

            if(!Array.isArray(dData)){

                dependsData = []
                for(let optGroup of Object.values(dData)){
                    for(let opt of optGroup){
                        dependsData.push(opt)
                    }
                }
            }

            this.depends = dependsData
        },
        hasDepends(element){
            return element.hasOwnProperty('depends') && this.model.custom.some((c) => c.uuid == element.depends)
        },
        optionDrag(i){
            return {
                group     : 'options-' + i,
                draggable : '.option--drag-' + i,
                handle    : '.option--handle-' + i
            }
        },
        computedDepends(el, parent){

            let depends = this.model.custom.filter((c) => {
                return c.hasOwnProperty('depends') && c.depends == el.uuid
            })

            for(let depend of depends){

                let index = this.model.custom.findIndex((c) => c.uuid == depend.uuid)
                if(parent != null && depend.options.hasOwnProperty(parent.uuid)){
                    Vue.set(this.model.custom[index], 'coptions', depend.options[parent.uuid])
                }else{
                    Vue.set(this.model.custom[index], 'coptions', [])
                }
            }
        },
        repairOptions(options){

            for(let i in options){
                let opt = options[i]
                if(typeof opt == 'string'){
                    options[i] = {
                        uuid  : this.$uuid(),
                        option: opt,
                        depend: ''
                    }
                }
            }

            return options
        },
        loadAttribute(attribute, element){
            element.options = this._.cloneDeep(attribute.options)
        },
        addValidationRule(event, element){
            
            if(!event){
                return
            }

            let ruleModel = {
                type: event.type,
                value: event.value || 0
            }

            if(element.rules.some(r => r.type == event.type)){
                return
            }

            element.rules.push(ruleModel)
        },
        deleteValidationRule(element, i){
            element.rules.splice(i, 1)
        },
        getValidationRuleLabel(type){
            let rule = this.formValidationRules.find(x => x.type == type)
            return rule ? rule.label : ''
        }
    },
    components: {
        draggable
    }
}

</script>