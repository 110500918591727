import config from 'src/config'
import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import Fn from 'plugins/Fn'
import Meta from 'vue-meta'
import Axios from 'plugins/Axios'
import ws from 'plugins/ws'
import Vuetify from 'vuetify'
import Gravatar from 'vue-gravatar'
import HeaderComponent from 'components/Header'
import Loader from 'components/Loader'
import LocalForage from 'vue-localforage'
import Moment from 'moment'
import VueMoment from 'vue-moment'
import InfiniteLoading from 'components/InfiniteLoading'
import Confirm from 'components/Confirm'
import WpConfirm from 'components/WpConfirm'
import Chart from 'components/Chart'
import Rank from 'components/Rank'
import Goal from 'components/Goal'
import Total from 'components/Total'
import Notes from 'components/Notes'
import Forms from 'components/Forms'
import Texts from 'components/Texts'
import Whatsapp from 'components/Whatsapp'
import FormData from 'components/FormData'
import Language from 'components/Language'
import NotificationBar from 'components/NotificationBar'
import FriendRequestBar from 'components/FriendRequestBar'
import ChatBar from 'components/ChatBar'
import VueLodash from 'vue-lodash'
import { i18n } from './i18n'
import cloneDeep from 'lodash/cloneDeep'
import CanAccount from 'components/CanAccount'
import CustomForm from 'components/form/CustomForm'
import Reset from 'components/Reset'
import Badge from 'components/Badge'
import AddFriend from 'components/social/AddFriend'
import VueProgressBar from 'vue-progressbar'
import StarRating from 'components/StarRating'

import 'moment/locale/es'
import '@babel/polyfill'
import 'leaflet/dist/leaflet.css'
import 'leaflet-control-geocoder/dist/Control.Geocoder.css'

if (process.browser) {

  const clipboard = require('v-clipboard')
  require('chart.js')
  require('hchs-vue-charts')
  Vue.use(window.VueCharts)
  Vue.use(clipboard.default)

  require('tinymce/tinymce')
  require('tinymce/themes/modern/theme')
  require('tinymce/plugins/media')
  require('tinymce/plugins/link')

  const editor = require('@tinymce/tinymce-vue')
  Vue.component('editor', editor.default)

  // Mapas
  const LMap = require('vue2-leaflet/dist/components/LMap').default
  const LTileLayer = require('vue2-leaflet/dist/components/LTileLayer').default
  const LMarker = require('vue2-leaflet/dist/components/LMarker').default
  const LCircleMarker = require('vue2-leaflet/dist/components/LCircleMarker').default
  const LTooltip = require('vue2-leaflet/dist/components/LTooltip').default
  const LControl = require('vue2-leaflet/dist/components/LControl').default
  const LIcon = require('vue2-leaflet/dist/components/LIcon').default

  Vue.component('l-map', LMap)
  Vue.component('l-tile-layer', LTileLayer)
  Vue.component('l-marker', LMarker)
  Vue.component('l-circle-marker', LCircleMarker)
  Vue.component('l-tooltip', LTooltip)
  Vue.component('l-control', LControl)
  Vue.component('l-icon', LIcon)

  const L = require('leaflet')
  const Icon = L.Icon
  
  delete Icon.Default.prototype._getIconUrl
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl      : require('leaflet/dist/images/marker-icon.png'),
    shadowUrl    : require('leaflet/dist/images/marker-shadow.png'),
  })

  require('leaflet-control-geocoder')
  require('leaflet.gridlayer.googlemutant')

  const LiquorTree = require('liquor-tree').default
  Vue.component('liquor-tree', LiquorTree)
}

const ProgressBarOpts = {
  color: '#ffffff',
  failedColor: '#ffffff',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, ProgressBarOpts)

Vue.use(Fn)
Vue.use(Meta)
Vue.use(Axios)
Vue.use(ws)
Vue.use(LocalForage)
Vue.use(VueMoment, {moment: Moment})
Vue.use(Vuetify, {
  theme: {
      primary  : '#169CB3',
      secondary: '#000000',
      accent   : '#000000',
      error    : '#B71C1C',
      success  : '#179875',
      grey     : '#8A8A8A',
  }
})
Vue.use(VueLodash, {
  name: 'lodash',
  lodash: { cloneDeep }
})

Vue.component('InfiniteLoading', InfiniteLoading)
Vue.component('v-gravatar', Gravatar)
Vue.component('HeaderComponent', HeaderComponent)
Vue.component('Loader', Loader)
Vue.component('Confirm', Confirm)
Vue.component('WpConfirm', WpConfirm)
Vue.component('Chart', Chart)
Vue.component('Rank', Rank)
Vue.component('Goal', Goal)
Vue.component('Total', Total)
Vue.component('Notes', Notes)
Vue.component('Forms', Forms)
Vue.component('FormData', FormData)
Vue.component('Language', Language)
Vue.component('NotificationBar', NotificationBar)
Vue.component('FriendRequestBar', FriendRequestBar)
Vue.component('ChatBar', ChatBar)
Vue.component('Texts', Texts)
Vue.component('WhatsappPreview', Whatsapp)
Vue.component('canAccount', CanAccount)
Vue.component('CustomForm', CustomForm)
Vue.component('ResetCmp', Reset)
Vue.component('Badge', Badge)
Vue.component('AddFriend', AddFriend)
Vue.component('StarRating', StarRating)

export function createApp() {

  const router = createRouter()
  const app = new Vue({
    router,
    i18n,
    render: h => h(App),
    data() {
      return {
        title   : 'Wappid',
        loader  : true,
        networkFound: {},
        limit   : 20,
        profile : {
          names      : '',
          simple_names: '',
          surname    : '',
          surname2   : '',
          email      : '',
          network    : {},
          mynetwork  : [],
          subnetworks: [],
          code       : '',
          rank       : 0,
          networkURL : '',
          referid    : null
        },
        rowsPageItems  : [20,50,100],
        rowsPageText: 'Filas por página',
        editor: {
          language : 'es',
          skin_url : 'public/skins/lightgray',
          language_url: 'public/langs/es.js',
          plugins: [
            'media',
            'link'
          ],
          media_url_resolver(data, resolver) {
            if (data.url.indexOf('youtube.com') !== 1) {
              
              let video =`<div class="video-responsive">
              <iframe src="${data.url}" frameborder="0" allowfullscreen></iframe>
              </div>`

              resolver({html:video})

            } else {
              resolver({html:''})
            }
          }
        },
        currentLanguage : 'es',
        fallbackLanguage: 'es',
        flanguages: [],
        currentNetwork: false,
        activeNetwork: false
      }
    },
    computed: {
      languages() {
        return config.languages
      },
      languagesLabel() {
        return [
          { value: 'pt', label: 'Portugués' },
          { value: 'en', label: 'Inglés' },
          { value: 'it', label: 'Italiano' },
          { value: 'fr', label: 'Francés' },
          { value: 'de', label: 'Alemán' }
        ]
      },
      hasCurrentNetwork() {
        return !this.$root.activeNetwork && Object.keys(this.$root.currentNetwork).length
      }
    },
    methods: {

        /** 
         * Establecer idioma actual
         * */
        setLanguage(lg) {
        
          let language = lg
          this.$moment.locale(language)
          this.$i18n.locale = language
          this.currentLanguage = language
        }
    }
  })
  
  return { app, router }
}