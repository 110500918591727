<template lang="pug">
    v-menu(
        v-model="notificationbar"
        attach='.notification-bar'
        fixed
        full-width
        lazy 
        :close-on-content-click="false" 
        max-height="400" 
        max-width="600"
        offset-y
        offset-x
        nudge-top="24"
        content-class="notification-menu"
        auto
        right
    )
        v-badge.mx-3.notification-btn(slot="activator" left color="red")
            small(v-if="!loadTotal" slot="badge") {{ unreadNotification > 9 ? '10+' : (unreadNotification < 0 ? '0' : unreadNotification) }}
            v-icon.cur-p(color="white" @click="fetchNotification(false)") notifications
        div.white.pa-2.notification
            div
                h4 {{ $t('Notificaciones') }}
                a: small(@click="readAllNotification") {{ $t('Marcar todo como leído') }}
            v-divider.x1.primary
            v-progress-linear(v-if="loadNotification" height="5" indeterminate color="primary")
            .notification-list(v-else-if="notifications.length")
                v-list.pt-3(light)
                    v-list-tile(v-for="(notification, i) in notifications" :key="'not'+i")
                        v-list-tile-content: small 
                            b(v-if="notification.read != 1" v-html="notification.render")
                            span(v-else v-html="notification.render")
                        v-list-tile-action
                            v-icon(color="primary" small right v-if="notification.read != 1 && !notification.load" @click="readNotification(i, notification)") check
                            v-progress-circular(v-else-if="notification.read != 1 && notification.load" indeterminate :size="16" :width="1" color="primary")
                            v-icon(v-if="!notification.loadDel" color="primary" small right @click="deleteNotification(i, notification)") delete
                            v-progress-circular(v-else indeterminate :size="16" :width="1" color="primary")
                infinite-loading(:infinite="infinite" ref="infinite")
            small.d-block.py-3.text-xs-center(v-if="!loadNotification && !notifications.length") {{ $t('No se han encontrado notificaciones') }}
</template>

<script>

import fn from 'mixins/fn'
export default {
    mixins: [fn],
    data(){
        return {
            loadNotification  : false,
            notifications     : [],
            unreadNotification: 2,
            limit             : this.$root.limit,
            offset            : 0,
            loadTotal         : false,
            notificationbar   : false,
            networkid         : ''
        }
    },
    mounted(){

        this.networkid = window.NETWORK.hasOwnProperty('uuid') ? window.NETWORK.uuid : ''

        this.loadTotal = true
        this.fetchTotal()

        setInterval(() => {
            this.fetchTotal()
        }, 60 * 1000)
    },
    methods: {

        readAllNotification(){

            this.$api(this, (xhr) => {
                xhr.post('/notification', this.$qs.stringify({
                    all: 1
                })).then((r) => {
                    
                    let data = r.data
                    if(data.response){
                        this.unreadNotification = 0
                        this.notifications = this.notifications.map((notification) => {

                            notification.read = 1
                            return notification
                        })
                    }
                }).catch(() => {})
            })
        },

        readNotification(i, notification){

            notification.load = true
            this.$api(this, (xhr) => {
                xhr.post('/notification', this.$qs.stringify({
                    all: 0,
                    uuid: notification.uuid
                })).then((r) => {
                    
                    let data = r.data
                    notification.load = false

                    if(data.response){
                        notification.read = 1
                        this.unreadNotification -= 1
                    }
                }).catch(() => notification.load = false)
            })
        },

        deleteNotification(i, notification){

            notification.loadDel = true
            this.$api(this, (xhr) => {
                xhr.delete('/notification', {params: {
                    uuid: notification.uuid
                }}).then((r) => {
                    
                    let data = r.data
                    notification.loadDel = false

                    if(data.response){

                        if(notification.read != 1){
                            this.unreadNotification -= 1
                        }
                        this.notifications.splice(i, 1)
                    }
                }).catch(() => notification.loadDel = false)
            })
        },

        fetchNotification(infinite=false){

            setTimeout(() => {

                if(!this.notificationbar){
                    return
                }

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.loadNotification = true
                    this.notifications = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    networkid: this.networkid
                }

                this.$api(this, (xhr) => {
                    xhr.get('/notification', { params: filter }).then((r) => {

                        if(!infinite) this.loadNotification = false
                        let data = r.data

                        data.data = data.data.map((dat) => {
                            dat.load = false
                            dat.loadDel = false
                            return dat
                        })

                        if(infinite){
                            if(data.response){
                                this.notifications = this.notifications.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.notifications = (data.response ? data.data : [])
                        }

                        if(data.response){
                            this.unreadNotification = data.unread
                        }

                    }).catch(() => {
                        this.loadNotification = false
                    })
                })

            }, 1)
        },

        fetchTotal(){

            this.$api(this, (xhr) => {
                xhr.get('/notification/total', {
                    params: {
                        networkid: this.networkid
                    }
                }).then((r) => {
                    
                    let data = r.data
                    this.unreadNotification = data.data
                    this.loadTotal = false
                }).catch(() => {})
            })
        },

        infinite(){
            setTimeout(() => this.fetchNotification(true) , 500)
        }
    }
}
</script>