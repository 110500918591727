<template lang="pug">
    div
        v-snackbar(
            :timeout="3000"
            :top="true"
            :right="true"
            color="error"
            v-model="error")
                | {{ errorText }}
                v-btn(flat dark @click.native="error = false") {{ $t('cerrar') }}
        v-snackbar(
            :timeout="3000"
            :top="true"
            :right="true"
            color="success"
            v-model="success")
                | {{ successText }}
                v-btn(flat dark @click.native="success = false") {{ $t('cerrar') }}
        v-dialog(v-model="modal" max-width="600")
            v-form
                v-card
                    v-card-title {{ $t('Cambiar contraseña') }}
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12)
                                v-text-field.medium(
                                v-model="model.password" 
                                :label="$t('Nueva contraseña')"
                                :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                :append-icon-cb="() => (e1 = !e1)"
                                :type="e1 ? 'password' : 'text'")
                            v-flex(xs12)
                                v-flex(xs12)
                                v-text-field.medium(
                                v-model="model.rpassword" 
                                :label="$t('Repetir nueva contraseña')"
                                :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                :append-icon-cb="() => (e2 = !e2)"
                                :type="e2 ? 'password' : 'text'")
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click.native="modal = false") {{ $t('Cancelar') }}
                            v-btn(color="primary" large @click.native="reset" :disabled="resDisabled") {{ resState }}
</template>
<script> 

    export default {
        data() {
            return {
                e1: true,
                e2: true,
                modal: false,
                modelSchema: {
                    password : '',
                    rpassword: '',
                    userid   : ''
                },
                model: {},
                error: false,
                errorText: '',
                success: false,
                successText: '',
                resOptions: {
                    normal: 'Cambiar',
                    load: 'Cambiando...'
                },
                resDisabled: false,
                resState: ''
            }
        },
        methods: {

            open(userid){

                this.model = this._.cloneDeep(this.modelSchema)
                this.resState = this.resOptions.normal

                this.e1 = true
                this.e2 = true
                
                this.modal = true
                this.model.userid = userid
            },

            reset(){

                this.resState = this.resOptions.load
                this.resDisabled = true

                this.$api(this, (xhr) => {
                    xhr.post('/reset', this.$qs.stringify(this.model)).then((r) => {

                        this.resState = this.resOptions.normal
                        this.resDisabled = false
                        
                        let data = r.data
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            this.modal = false
                            this.success = true
                            this.successText = data.message
                            this.model = this._.cloneDeep(this.modelSchema)
                        }
                    }).catch(() => {
                        this.resState = this.resOptions.normal
                        this.resDisabled = false
                    })
                })
            }
        }
    }

</script>