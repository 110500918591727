<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="canColor"
        v-model="canMsj")
            | {{ canText }}
            v-btn(flat dark @click.native="canMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="600px" v-model="can")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="can=false")
                            v-icon close
                    h3.full-width {{ $t('Permisos del usuario') }}
                v-card-text.pt-2
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout.mt-2(row wrap)
                            template(v-for="c in model")
                                v-flex(xs12)
                                    h2 {{ c.name }}
                                v-flex(xs12 v-for="(dat, i) in c.data" :key="'c'+i")
                                    label {{$t(dat.name)}}
                                    v-divider.mt-2
                                    .switch-group
                                        label {{ $t('No') }}
                                        v-switch(:label="$t('Si')" color="success" v-model="dat.default")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="can=false") {{$t('Aceptar')}}
                        v-btn(v-if="!preload" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
</template>

<script>

import config from 'src/config'
import Vue from 'vue'

export default {
    data(){
        return {
            can      : false,
            canMsj   : false,
            canColor : '',
            canText  : '',
            loadingBtn: false,
            preload   : false,
            model: [],
            current: {}
        }
    },
    computed: {
        profile(){
            return this.$root.profile
        }
    },
    methods:{

        async open(item){

            this.model = []
            this.can = true
            this.preload = true
            this.current = this._.cloneDeep(item)
            
            await this.fetch()
            this.preload = false
        },
        save(){

            let post = {}
            post.can = JSON.stringify(this._.cloneDeep(this.model))
            post.networkid = this.current.networkid
            post.uuid = this.current.uuid

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/user/can', this.$qs.stringify(post)).then(async (r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.canMsj = true

                    if(data.response){
                        this.canColor = 'success'
                        this.canText = data.message
                        this.preload = true
                        await this.fetch()
                        this.preload = false
                    }else{
                        this.canColor = 'error'
                        this.canText = data.message
                    }
                }).catch(() => {})
            })
        },
        fetch(){

            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/user/can', {
                        params: { 
                            networkid: this.current.networkid,
                            uuid: this.current.uuid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.model = this._.cloneDeep(data.data)
                            resolve()
                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        }
    }
}

</script>