<template lang="pug">
    div
        v-tabs(grow)
            v-tab {{ $t(customFormTitle) }}
            v-tab {{ $t('Atributos') }}
            v-tab-item
                v-layout.mt-2(row wrap)
                    v-flex(xs12)
                        v-select(:label="$t('Tipo de elemento')" v-model="element" :return-object="true" :items="elements" item-text="label" item-value="type")
                    template(v-if="Object.keys(element).length")
                        v-flex(xs12)
                            v-text-field(v-model="element.placeholder" :label="$t('Nombre del campo')")
                        template(v-if="elementDetail")
                            v-flex(xs12)
                                v-text-field(multi-line rows="2" v-model="element.description" :label="$t('Descripción del campo')")
                            v-flex(xs12)
                                h3.mb-3 {{$t('Imagen')}}
                                v-btn.btn-file(color="success" outline block large) 
                                    v-icon(left) file_upload
                                    | {{$t('Subir imagen')}}
                                    input(type='file' accept="image/png,image/jpg,image/jpeg,image/gif" @change='fileChange($event, "descImg", "descImage")')
                                .img-file(v-if="descImg != undefined && descImg != null && descImg.length")
                                    img(:src="descImg")
                                    v-icon(@click='removeImage("descImg", "descImage")') close
                        v-flex(xs12 v-if="enableDepends && (element.type == 4 || element.type == 6)")
                            v-select(:label="$t('Depende de')" clearable v-model="element.depends" :items="listDepends(element)" item-text="placeholder" item-value="uuid" @input="updateDepends")
                        v-flex(xs12 v-if="[2,3,4,6,7].includes(element.type)")

                            template(v-if="element.type == 7")
                                h4 {{ $t('Tipo de escala') }}
                                v-layout(row wrap)
                                    v-flex(xs12)
                                        label {{$t('Rango de números')}}
                                        v-divider.mt-2
                                        .switch-group
                                            label {{ $t('No') }}
                                            v-switch(:label="$t('Si')" color="success" v-model="element.config.is_rank")
                                    v-flex(xs6 md12 v-if="element.config.is_rank")
                                        v-text-field(v-model="element.config.min" min="0" max="20" type="number" :label="$t('Min')")
                                    v-flex(xs6 md12 v-if="element.config.is_rank")
                                        v-text-field(v-model="element.config.max" min="0" max="20" type="number" :label="$t('Max')")
                                    template(v-if="element.config.hasOwnProperty('enableAbText')")
                                        v-flex(xs12)
                                            label {{$t('Texto de inicio y final')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{ $t('No') }}
                                                v-switch(:label="$t('Si')" color="success" v-model="element.config.enableAbText")
                                        v-flex(xs12 v-if="element.config.enableAbText")
                                            v-text-field(v-model="element.config.before" :label="$t('Texto inicial')")
                                        v-flex(xs12 v-if="element.config.enableAbText")
                                            v-text-field(v-model="element.config.after" :label="$t('Texto final')")
                                    v-flex(xs12 v-if="element.config.is_rank")
                                        v-text-field(v-model="element.config.maxWidth" min="0" type="number" :label="$t('Anchura máxima')")

                            template(v-if="element.type == 7 ? !element.config.is_rank : true")
                                h4 {{ $t('Opciones') }}
                                v-layout(row wrap v-if="!hasDepends(element)")
                                    v-flex(xs12)
                                        v-select(clearable :return-object="true" item-value="uuid" item-text="name" :items="listAttributes(element)" :label="$t('Cargar atributo')" @input="loadAttribute($event, element)")
                                    v-flex(xs12)
                                        v-text-field(multi-line rows="2" v-model="option" :label="$t('Opción')")
                                    //- Selección de imagen
                                    template(v-if="element.type == 6")
                                        v-flex(xs12)
                                            v-text-field(multi-line rows="2" v-model="optionDescription" :label="$t('Descripción')")
                                        v-flex(xs12)
                                            h3.mb-3 {{$t('Imagen')}}
                                            v-btn.btn-file(color="success" outline block large) 
                                                v-icon(left) file_upload
                                                | {{$t('Subir imagen')}}
                                                input(type='file' accept="image/png,image/jpg,image/jpeg,image/gif" @change='fileChange($event, "optionImg", "optionImage")')
                                            .img-file(v-if="optionImg.length")
                                                img(:src="optionImg")
                                                v-icon(@click='removeImage("optionImg", "optionImage")') close
                                    v-flex(xs12)
                                        v-btn(depressed small color="primary" @click="addOption(element)") {{ $t('Agregar opción') }}
                                    v-flex(xs12 v-if="element.options.length")
                                        draggable(tag="v-list" style="min-height:1px" v-model="element.options" :options="optionsDrag")
                                            v-list-tile.option--drag(v-for="(option, i) in element.options" :key="'opt-'+i")
                                                v-list-tile-avatar
                                                    v-icon.option--handle(style="cursor:move") list
                                                v-list-tile-content
                                                    v-list-tile-title
                                                        img.mr-2(v-if="element.type == 6 && (option.image == undefined || option.image == '')" :src="uploadurl + option.preview" style="width:24px;vertical-align:middle;")
                                                        img.mr-2(v-else-if="element.type == 6" :src="option.preview" style="width:24px;vertical-align:middle;")
                                                        | {{ typeof option == 'string' ? option : option.option }}
                                                v-list-tile-action(@click="element.options.splice(i, 1)")
                                                    v-icon(color="primary") close
                            
                            v-layout(row wrap v-else v-for="(depend, i) in depends")
                                v-flex(xs12)
                                    h4 {{ depend.option }}
                                    v-text-field(multi-line rows="2" v-model="depend.depend" :label="$t('Opción')")
                                //- Selección de imagen
                                template(v-if="element.type == 6")
                                    v-flex(xs12)
                                        v-text-field(multi-line rows="2" v-model="depend.description" :label="$t('Descripción')")
                                    v-flex(xs12)
                                        h3.mb-3 {{$t('Imagen')}}
                                        v-btn.btn-file(color="success" outline block large) 
                                            v-icon(left) file_upload
                                            | {{$t('Subir imagen')}}
                                            input(type='file' accept="image/png,image/jpg,image/jpeg,image/gif" @change='fileChange($event, "img", "image", depend)')
                                        .img-file(v-if="depend.img.length")
                                            img(:src="depend.img")
                                            v-icon(@click='removeImage("img", "image", depend)') close
                                v-flex(xs12)
                                    v-btn(depressed small color="primary" @click="addOption(element, depend, i)") {{ $t('Agregar opción') }}
                                v-flex(xs12 v-if="Object.keys(element.options).length")
                                    draggable(tag="v-list" style="min-height:1px" v-model="element.options[depend.uuid]" :options="optionDrag(i)")
                                        v-list-tile(:class="'option--drag-'+i" v-for="(option, j) in element.options[depend.uuid]" :key="'opt-'+i+'-'+j")
                                            v-list-tile-avatar
                                                v-icon(:class="'option--handle-'+i" style="cursor:move") list
                                            v-list-tile-content
                                                v-list-tile-title
                                                    img.mr-2(v-if="element.type == 6 && (option.image == undefined || option.image == '')" :src="uploadurl + option.preview" style="width:24px;vertical-align:middle;")
                                                    img.mr-2(v-else-if="element.type == 6" :src="option.preview" style="width:24px;vertical-align:middle;")
                                                    | {{ option.option }}
                                            v-list-tile-action(@click="element.options[depend.uuid].splice(j, 1)")
                                                v-icon(color="primary") close
                        v-flex(xs12 v-if="requiredOption")
                            label {{$t('Obligatorio')}}
                            v-divider.mt-2
                            .switch-group
                                label {{ $t('No') }}
                                v-switch(:label="$t('Si')" color="success" v-model="element.required")
                        v-flex(xs12 v-if="element.type == 6 && !element.depends")
                            label {{$t('Selección multiple')}}
                            v-divider.mt-2
                            .switch-group
                                label {{ $t('No') }}
                                v-switch(:label="$t('Si')" color="success" v-model="element.multiple")
                        template(v-if="limitMultipleSelection && (element.type == 2 || (element.type == 6 && !element.depends && element.multiple)) && element.config.hasOwnProperty('min')")
                            v-flex(xs12)
                                v-text-field(v-model="element.config.min" hint="Ingresa 0 si no hay elementos mínimos a seleccionar" min="0" type="number" :label="$t('Selección mínima')")
                            v-flex(xs12)
                                v-text-field(v-model="element.config.max" hint="Ingresa 0 si no hay elementos máximos a seleccionar" min="0" type="number" :label="$t('Selección máxima')")
                        v-flex(xs12 v-if="visibility")
                            label {{$t('Visible para el público')}}
                            v-divider.mt-2
                            .switch-group
                                label {{ $t('No') }}
                                v-switch(:label="$t('Si')" color="success" v-model="element.visibility")
                    v-flex(xs12)
                        v-btn(small depressed color="primary" @click="addElement") {{ $t(element.uuid ? 'Guardar' : 'Agregar') }}
                v-divider.mt-2
                h3(v-if="model.custom.length") {{ $t('Vista previa del formulario') }}
                draggable.mt-2.row.wrap(tag="v-layout" v-model="model.custom" :options="formDrag")
                    v-flex.form--drag(xs12 v-for="(el, i) in model.custom" :key="'el-'+i")
                        //- Acciones
                        b.mb-2.d-block: small {{ $t('ID') }}: {{ el.uuid }}
                        div.d-block
                            v-icon.form--handle(style="cursor:move") list
                            v-icon.cur-p(@click="editElement(i)") edit
                            v-icon.cur-p(@click="$refs.delete.open(i)") delete
                        // Detalle de la pregunta
                        template(v-if="elementDetail")
                            p(v-if="el.description != null && el.description.toString().trim().length") {{ el.description }}
                            .image-desc(v-if="el.hasOwnProperty('preview') && el.preview != null && el.preview.toString().length")
                                img(:src="el.preview.toString().includes('data:image') ? el.preview.toString() : (uploadurl + el.preview.toString())")
                        //-Entrada de texto
                        template(v-if="el.type == 1")
                            v-text-field(:label="el.placeholder" :required="el.required")
                        //-Entrada de párrafo
                        template(v-if="el.type == 5")
                            v-text-field(multi-line rows="2" :label="el.placeholder" :required="el.required")
                        //-Selección multiple
                        template(v-if="el.type == 2")
                            label.d-block.mt-3 {{ el.placeholder }}
                            v-checkbox(v-for="opt in el.options" :label="(typeof opt == 'string' ? opt : opt.option)")
                        //-Selección
                        template(v-if="el.type == 3")
                            v-radio-group
                                label {{ el.placeholder }}{{el.required ? '&bull;':''}}
                                v-radio(v-for="opt in el.options" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                        //-Listado
                        template(v-if="el.type == 4")
                            v-select(clearable v-if="el.depends == null ? true : !el.depends.length" :return-object="true" :required="el.required" item-value="uuid" item-text="option" :items="el.options" :label="el.placeholder" @input="computedDepends(el, $event)")
                            v-select(clearable v-else :items="el.hasOwnProperty('coptions') ? el.coptions : []" :required="el.required" item-value="uuid" item-text="option" :label="el.placeholder" :return-object="true" @input="computedDepends(el, $event)")
                        //-Selección de imagen
                        template(v-if="el.type == 6 && (el.depends == null ? true : !el.depends.length)")
                            label.d-block.mt-3 
                                | {{ el.placeholder }}
                                span.red--text(v-if="el.required") *
                            div.option-check(v-for="option in el.options")
                                small {{ option.option }}
                                .check-img(@click="toogleActive(el.options, option, el)" :class="{'active': option.active}" style="width:150px")
                                    img(:src="option.preview.includes('data:image') ? option.preview : (uploadurl + option.preview)")
                                small {{ option.description }}
                        template(v-if="el.type == 6 && !(el.depends == null ? true : !el.depends.length)")
                            label.d-block.mt-3
                                | {{ el.placeholder }}
                                span.red--text(v-if="el.required") *
                            div.option-check(v-for="option in el.hasOwnProperty('coptions') ? el.coptions : []")
                                small {{ option.option }}
                                .check-img(@click="toogleActive(el.coptions, option, el)" :class="{'active': option.active}" style="width:150px")
                                    img(:src="option.preview.includes('data:image') ? option.preview : (uploadurl + option.preview)")
                                small {{ option.description }}
                        
                        //-Escala
                        template(v-if="el.type == 7")
                            label.d-block.mt-3 {{ el.placeholder }}{{el.required ? '&bull;':''}}
                            .likert-scale-group(v-if="el.config.is_rank")
                                template(v-if="el.config.hasOwnProperty('enableAbText') && el.config.enableAbText")
                                    span.mr-2(v-if="el.config.before.toString().trim().length") {{ el.config.before }}
                                v-radio-group.likert-scale(:style="{maxWidth: (el.config.maxWidth <= 0 ? 'initial': el.config.maxWidth+'px')}")
                                    v-radio(v-for="num in range(el.config.min, el.config.max)" :value="num" :label="num.toString()")
                                template(v-if="el.config.hasOwnProperty('enableAbText') && el.config.enableAbText")
                                    span.ml-2(v-if="el.config.after.toString().trim().length") {{ el.config.after }}
                            v-radio-group.likert-scale(v-else :style="{maxWidth: (el.config.maxWidth <= 0 ? 'initial': el.config.maxWidth+'px')}")
                                v-radio(v-for="opt in el.options" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
            v-tab-item
                v-flex(xs12)
                    v-text-field(v-model="attribute.name" :label="$t('Nombre del atributo')")
                v-flex(xs12 v-if="imageSelection")
                    label {{$t('Selección de imagen')}}
                    v-divider.mt-2
                    .switch-group
                        label {{ $t('No') }}
                        v-switch(:label="$t('Si')" color="success" v-model="attribute.isimage")
                h4 {{ $t('Opciones') }}
                v-layout(row wrap)
                    v-flex(xs12)
                        v-text-field(multi-line rows="2" v-model="attribute.option" :label="$t('Opción')")
                    template(v-if="attribute.isimage")
                        v-flex(xs12)
                            v-text-field(multi-line rows="2" v-model="attribute.description" :label="$t('Descripción')")
                        v-flex(xs12)
                            h3.mb-3 {{$t('Imagen')}}
                            v-btn.btn-file(color="success" outline block large) 
                                v-icon(left) file_upload
                                | {{$t('Subir imagen')}}
                                input(type='file' accept="image/png,image/jpg,image/jpeg,image/gif" @change='fileChange($event, "img", "image", attribute)')
                            .img-file(v-if="attribute.img.length")
                                img(:src="attribute.img")
                                v-icon(@click='removeImage("img", "image", attribute)') close
                    v-flex(xs12)
                        v-btn(depressed small color="primary" @click="addOptionAttribute(attribute)") {{ $t('Agregar opción') }}
                    v-flex(xs12 v-if="attribute.options.length")
                        draggable(tag="v-list" style="min-height:1px" v-model="attribute.options" :options="optionsDrag")
                            v-list-tile.option--drag(v-for="(option, i) in attribute.options" :key="'opt-'+i")
                                v-list-tile-avatar
                                    v-icon.option--handle(style="cursor:move") list
                                v-list-tile-content
                                    v-list-tile-title
                                        img.mr-2(v-if="attribute.isimage && (option.image == undefined || option.image == '')" :src="uploadurl + option.preview" style="width:24px;vertical-align:middle;")
                                        img.mr-2(v-else-if="attribute.isimage" :src="option.preview" style="width:24px;vertical-align:middle;")
                                        | {{ typeof option == 'string' ? option : option.option }}
                                v-list-tile-action(@click="attribute.options.splice(i, 1)")
                                    v-icon(color="primary") close
                    v-flex(xs12)
                        v-btn(small depressed color="primary" @click="addAttribute") {{ $t(attribute.uuid ? 'Guardar' : 'Agregar') }}
                v-divider.mt-2
                h3(v-if="model.attribute.length") {{ $t('Listado de atributos') }}
                v-flex.form--drag(xs12 v-for="(at, i) in model.attribute" :key="'at-'+i")
                    //- Acciones
                    div.d-block
                        v-icon.form--handle(style="cursor:move") list
                        v-icon.cur-p(@click="editAttribute(i)") edit
                        v-icon.cur-p(@click="$refs.deleteAttribute.open(i)") delete
                    //-Listado
                    template(v-if="at.isimage")
                        label.d-block.mt-3
                            | {{ at.name }}
                        div.option-check(v-for="option in at.options")
                            small {{ option.option }}
                            .check-img(style="width:150px")
                                img(:src="option.preview.includes('data:image') ? option.preview : (uploadurl + option.preview)")
                            small {{ option.description }}
                    v-select(v-else clearable item-value="uuid" item-text="option" :items="at.options" :label="at.name")
        confirm(ref="delete" @action="deleteElement" :title="$t('Eliminar campo')" :message="$t('¿Estás seguro de eliminar este campo, la información registrada previamente ya no sera visible?')")
        confirm(ref="deleteAttribute" @action="deleteAttribute" :title="$t('Eliminar atributo')" :message="$t('¿Estás seguro de eliminar este atributo, la información registrada previamente ya no sera visible?')")
</template>

<script>

import draggable from 'vuedraggable'
import config from 'src/config'
import Vue from 'vue'

export default {
    props: {
        customFormTitle: {
            type: String,
            default: 'Formularios personalizados'
        },
        visibility: {
            type: Boolean,
            default: true
        },
        mCustom: {
            type: Array,
            default: () => []
        },
        mAttribute: {
            type: Array,
            default: () => []
        },
        imageSelection: {
            type: Boolean,
            default: false
        },
        requiredOption: {
            type: Boolean,
            default: true
        },
        enableDepends: {
            type: Boolean,
            default: true
        },
        elementDetail: {
            type: Boolean,
            default: false
        },
        scaleSelection: {
            type: Boolean,
            default: false
        },
        limitMultipleSelection: {
            type: Boolean,
            default: false
        },
        showOther: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formMsj   : false,
            formColor : '',
            formText  : '',
            elements: [],
            depends: [],
            option: '',
            optionDescription: '',
            optionImg: '',
            optionImage: '',
            descImg: '',
            descImage: '',
            element: {},
            attributeSchema: {
                name       : '',
                option     : '',
                options    : [],
                isimage    : false,
                description: '',
                image      : '',
                img        : ''
            },
            attribute: {},
            model: {
                custom    : [],
                attribute : []
            },
            optionsDrag: {
                group    : 'options',
                draggable: '.option--drag',
                handle   : '.option--handle'
            },
            formDrag: {
                group    : 'forms',
                draggable: '.form--drag',
                handle   : '.form--handle'
            },
            model: {
                custom: [],
                attribute: []
            },
            selectedRadio: ''
        }
    },
    computed: {
        elementSchema(){

            let defaultItems = {
                description: '',
                image: '',
                placeholder: '',
                required   : false,
                visibility : true
            }

            let items = [
                {
                    type       : 1,
                    label      : this.$t('Entrada de texto'),
                    ...defaultItems
                },
                {
                    type       : 5,
                    label      : this.$t('Entrada de párrafo'),
                    ...defaultItems
                },
                {
                    type       : 2,
                    label      : this.$t('Selección multiple'),
                    options    : [],
                    ...defaultItems,
                    config: {
                        min: 0,
                        max: 0
                    }
                },
                {
                    type       : 3,
                    label      : this.$t('Selección'),
                    options    : [],
                    ...defaultItems
                },
                {
                    type       : 4,
                    label      : this.$t('Listado'),
                    options    : [],
                    depends    : '',
                    ...defaultItems
                },
            ]

            if(this.imageSelection){
                items.push({
                    type       : 6,
                    label      : this.$t('Selección de imagen'),
                    options    : [],
                    depends    : '',
                    multiple: false,
                    ...defaultItems,
                    config: {
                        min: 0,
                        max: 0
                    }
                })
            }

            if (this.scaleSelection) {
                items.push({
                    type       : 7,
                    label      : this.$t('Escala'),
                    options: [],
                    config: {
                        is_rank: true,
                        min: 1,
                        max: 10,
                        maxWidth: 0,
                        enableAbText: false,
                        after: '',
                        before: ''
                    },
                    ...defaultItems
                })
            }

            return items
        },
        uploadurl(){
            return config.uploadurl
        }
    },
    watch: {
        model:{
            deep: true,
            handler(){
                this.$emit('update:mCustom', this.model.custom)
            }
        }
    },
    created(){
        this.elements        = this._.cloneDeep(this.elementSchema)
        this.attribute       = this._.cloneDeep(this.attributeSchema)
        this.model.custom    = this.mCustom
        this.model.attribute = this.mAttribute
    },
    methods: {

        range(start, end) {
            let length = (Number(end)-Number(start))+1
            return Array.from({length}, (_, i) => Number(start) + i)
        },

        toogleActive(options, option, el){

            if(!(el.multiple && !el.depends)){

                options = options.map((opt) => {
                    if(opt.uuid != option.uuid){
                        opt.active = false
                    }
                    return opt
                })
            }

            if ((el.multiple && !el.depends) && el.config.max > 0 && options.filter(o => o.active).length >= el.config.max) {
                option.active = false
            } else {
                option.active = option.active ? false : true   
            }
            
            // No permitido en opción multiple
            if(el.multiple){
                this.computedDepends(el, {})
                return
            }

            if(option.active){
                this.computedDepends(el, option)
            }else{
                this.computedDepends(el, {})
            }
        },
        fileChange(e, model, save, base) {

            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], model, save, base)
        },
        createImage(file, model, save, base = null) {

            let reader = new FileReader();
            reader.onload = (e) => {
                if(base != null){
                    base[model] = e.target.result
                }else{
                    this[model] = e.target.result
                }
            }

            reader.readAsDataURL(file)
            if(base != null){
                base[save] = file
            }else{
                this[save] = file
            }
        },
        removeImage(model, save, base = null) {

            if(base != null){
                base[model] = ''
                base[save]  = ''
            }else{
                this[model] = ''
                this[save]  = ''
            }
        },
        addElement(){

            try{

                if(!Object.keys(this.element).length){
                    throw "Seleccione un tipo de elemento"
                }

                if(!this.element.placeholder.trim().length){
                    throw "Inserte el nombre del campo"
                }

                if(this.element.type != 1 && this.element.type != 5){
                    
                    try {

                        let scale_is_rank = (this.element.type == 7 ? !this.element.config.is_rank : true)
                        if(scale_is_rank && this.element.hasOwnProperty('depends')){
                            
                            if((this.element.depends == null || !this.element.depends.length) && !Object.keys(this.element.options).length){
                                throw false
                            }

                            if(!Object.keys(this.element.options).length){
                                throw false
                            }

                        } else if(scale_is_rank && (Array.isArray(this.element.options) ? !this.element.options.length : !Object.keys(this.element.options).length)){
                            throw false
                        }

                    } catch (e) {
                        throw "Seleccione al menos una opción"
                    }
                }

                if (this.element.type == 7 && this.element.config.is_rank && this.element.config.min >= this.element.config.max) {
                    throw "El rango es invalido"
                }

                // Agregar imagen
                if (this.descImg != undefined && this.descImg != null && this.descImg.length) {
                    this.element.image = this.descImage
                    this.element.preview = this.descImg.replace(this.uploadurl, '')
                } else {
                    this.element.image = ''
                    this.element.preview = ''
                }

                if(this.element.hasOwnProperty('uuid') && this.model.custom.some((f) => f.uuid == this.element.uuid)){
                    let i = this.model.custom.findIndex((f) => f.uuid == this.element.uuid)
                    this.model.custom.splice(i, 1, this.element)
                }else{
                    this.element.uuid = this.$uuid()
                    this.model.custom.push(this.element)
                }

                this.elements = this._.cloneDeep(this.elementSchema)
                this.element = {}

                this.descImage = ''
                this.descImg = ''

            }catch(e){
                this.$emit('error', this.$t(e))
            }
        },
        addOption(element, depend={}, index=0){

            let isDepend = Object.keys(depend).length ? true : false
            if(isDepend ? !depend.depend.trim().length : !this.option.trim().length){
                this.$emit('error', this.$t('Inserte una opción'))
                return
            }

            // Selección de imagen
            if(element.type == 6){
                
                if(!isDepend ? !this.optionImg.length : !depend.img.length){
                    this.$emit('error', this.$t('Inserte una imagen'))
                    return
                }
            }

            if(isDepend){

                let isParent = this.element.options.hasOwnProperty(depend.uuid)
                let option   = {
                    uuid       : this.$uuid(),
                    parent     : depend.uuid,
                    option     : depend.depend,
                    depend     : '',
                    image      : depend.image,
                    description: depend.description,
                    preview    : depend.img,
                    active     : false
                }

                if(isParent){
                    this.element.options[depend.uuid].push(option) 
                }else{

                    if(Array.isArray(this.element.options)){
                        this.element.options = {
                            [depend.uuid]: [option]
                        }
                    }else{
                        Vue.set(this.element.options, depend.uuid, [option])
                    }
                }

                depend.depend = ''
                depend.img = ''
                depend.image = ''
                depend.description = ''

            }else{
                this.element.options.push({
                    uuid       : this.$uuid(),
                    option     : this.option,
                    depend     : '',
                    image      : this.optionImage,
                    description: this.optionDescription,
                    preview    : this.optionImg,
                    active     : false
                })
            }

            this.option      = ''
            this.optionImage = ''
            this.optionImg   = ''
            this.optionDescription = ''
        },
        deleteElement(i){
            this.model.custom.splice(i, 1)
            this.$refs.delete.close()
        },
        editElement(i) {

            let element = this.model.custom[i]
            let elementSchema = this.elementSchema.find(el => el.type == element.type)

            if (elementSchema) {
                element = this._.merge(elementSchema, element)
            }
            
            this.element = this._.cloneDeep(element)

            // Agregar imagen vista previa
            if (this.element.hasOwnProperty('preview') && this.element.preview != null && this.element.preview.toString().length) {
                this.descImg = this.element.preview.toString().includes('data:image') ? this.element.preview.toString() : (this.uploadurl + this.element.preview.toString())
            }

            this.updateDepends()
        },
        addOptionAttribute(attribute){

            if(!attribute.option.trim().length){
                this.$emit('error', this.$t('Inserte una opción'))
                return
            }

            if(attribute.isimage && !attribute.img.length){
                this.$emit('error', this.$t('Inserte una imagen'))
                return
            }

            attribute.options.push({
                uuid       : this.$uuid(),
                option     : attribute.option,
                image      : attribute.image,
                description: attribute.description,
                preview    : attribute.img,
                active     : false,
            })

            attribute.option = ''
            attribute.depend = ''
            attribute.img = ''
            attribute.image = ''
            attribute.description = ''
        },
        addAttribute(){

            try{

                if(!this.attribute.name.trim().length){
                    throw "Inserte el nombre del atributo"
                }

                if(!this.attribute.options.length){
                    throw "Seleccione al menos una opción"
                }

                if(this.attribute.hasOwnProperty('uuid') && this.model.attribute.some((f) => f.uuid == this.attribute.uuid)){
                    let i = this.model.attribute.findIndex((f) => f.uuid == this.attribute.uuid)
                    this.model.attribute.splice(i, 1, this.attribute)
                }else{
                    this.attribute.uuid = this.$uuid()
                    this.model.attribute.push(this.attribute)
                }

                this.attribute = this._.cloneDeep(this.attributeSchema)

            }catch(e){
                this.$emit('error', this.$t(e))
            }
        },
        deleteAttribute(i){
            this.model.attribute.splice(i, 1)
            this.$refs.deleteAttribute.close()
        },
        editAttribute(i){
            this.attribute = this._.cloneDeep(this.model.attribute[i])
        },
        labelDepends(depends){

            depends = depends.map((tag) => {
                let label = this.rows.find((t) => t.tag == tag)
                return label.label
            })

            return depends.join(', ')
        },
        listAttributes(element){
            
            return this.model.attribute.filter((a) => {
                return element.type == 6 ? (a.isimage != undefined && a.isimage) : !a.isimage
            })
        },
        listDepends(element){

            let list = []

            if(element.type == 6){
                list = this.model.custom.filter((m) => m.type == 6)
            }

            if(element.type == 4){
                list = this.model.custom.filter((m) => m.type == 4)
            }
            
            return list.map((l) => {
                return {
                    uuid        : l.uuid,
                    placeholder : l.placeholder,
                    options     : l.options,
                    optionsv    : (new Array(l.options.length)).fill('')
                }
            })
        },
        updateDepends(){
            
            let depends = this.model.custom.find((c) => c.uuid == this.element.depends)
            let dData = depends ? depends.options : []
            let dependsData = this._.cloneDeep(dData)

            if(!Array.isArray(dData)){

                dependsData = []
                for(let optGroup of Object.values(dData)){
                    for(let opt of optGroup){

                        if(opt.img != undefined){
                            opt.img = ''
                        }
                        if(opt.image != undefined){
                            opt.image = ''
                        }
                        if(opt.description != undefined){
                            opt.description = ''
                        }

                        dependsData.push(opt)
                    }
                }
            }

            this.depends = dependsData
        },
        hasDepends(element){
            return element.hasOwnProperty('depends') && this.model.custom.some((c) => c.uuid == element.depends)
        },
        optionDrag(i){
            return {
                group     : 'options-' + i,
                draggable : '.option--drag-' + i,
                handle    : '.option--handle-' + i
            }
        },
        computedDepends(el, parent){

            let depends = this.model.custom.filter((c) => {
                return c.hasOwnProperty('depends') && c.depends == el.uuid
            })

            for(let depend of depends){

                let index = this.model.custom.findIndex((c) => c.uuid == depend.uuid)
                if(parent != null && depend.options.hasOwnProperty(parent.uuid)){

                    let options = depend.options[parent.uuid].map((option) => {
                        option.active = false
                        return option
                    })

                    Vue.set(this.model.custom[index], 'coptions', depend.options[parent.uuid])
                }else{
                    Vue.set(this.model.custom[index], 'coptions', [])
                }
            }
        },
        repairOptions(options){

            for(let i in options){
                let opt = options[i]
                if(typeof opt == 'string'){
                    options[i] = {
                        uuid  : this.$uuid(),
                        option: opt,
                        depend: ''
                    }
                }else{

                    if(opt.img != undefined){
                        opt.img = ''
                    }
                    if(opt.image != undefined){
                        opt.image = ''
                    }
                    if(opt.description != undefined){
                        opt.description = ''
                    }
                }
            }

            return options
        },
        loadAttribute(attribute, element){
            element.options = this._.cloneDeep(attribute.options)
        }
    },
    components: {
        draggable
    }
}
</script>