<template lang="pug">
v-layout(row wrap)
    v-flex(xs12 v-for="(c, i) in customForm" :key="'c'+i")
        v-text-field(v-if="c.type == 1" v-model="value[c.uuid]" :label="$t(c.placeholder)" type="text" :required="requiredOption && c.required")
        v-text-field(multi-line v-if="c.type == 5" v-model="value[c.uuid]" :label="$t(c.placeholder)" type="text" :required="requiredOption && c.required")
        template(v-if="c.type == 2")
            label.d-block.mb-1 {{ c.placeholder }}{{(requiredOption && c.required) ? '&bull;':''}}
            v-checkbox(v-for="(opt, i) in c.options" :key="'opt'+i" v-model="value[c.uuid]" :label="(typeof opt == 'string' ? opt : opt.option)" :value="(typeof opt == 'string' ? opt : opt.option)")
        template(v-if="c.type == 4")
            v-select(clearable v-if="!enableDepends && (c.depends == null ? true : !c.depends.length)" v-model="value[c.uuid]" :return-object="true" :items="c.options" item-value="uuid" item-text="option" :label="c.placeholder" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" :required="requiredOption && c.required" @input="computedDepends(c, $event)")
            v-select(clearable v-else v-model="value[c.uuid]" :items="c.hasOwnProperty('coptions') ? c.coptions : []" :required="c.required" item-value="uuid" item-text="option" :label="c.placeholder" :return-object="true" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" @input="computedDepends(c, $event)")
        v-radio-group(v-if="c.type == 3" v-model="value[c.uuid]")
            label {{ c.placeholder }}{{(requiredOption && c.required) ? '&bull;':''}}
            v-radio(v-for="(opt, i) in c.options" :key="'opt2'+i" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
</template>

<script>

import fn from 'mixins/fn'
export default {
    mixins: [fn],
    props: {
        customForm: {
            type: Array,
            required: true
        },
        value: {},
        requiredOption: {
            type: Boolean,
            default: true
        },
        enableDepends: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        customForm: {
            deep: true,
            handler() {
                this.setFormDefaults()
            }
        }
    },
    created() {
        this.setFormDefaults()  
    },
    methods: {
        setFormDefaults() {
            let values = {}
            for (let c of this.customForm) {
                if (this.value.hasOwnProperty(c.uuid)) {
                    if (c.type == 4) {
                        values[c.uuid] = c.options.find(o => o.option == this.value[c.uuid])
                    } else {
                        values[c.uuid] = this.value[c.uuid]
                    }
                    continue
                }
                if (c.type == 2) {
                    values[c.uuid] = []
                } else {
                    values[c.uuid] = ''
                }
            }
            this.$emit('input', values)
        },
        computedDepends(c, e) {}
    }
}
</script>