<template lang="pug">
    v-dialog(v-model='confirm' max-width='700px')
        v-form
            v-card
                v-card-title.pb-0.justify-center {{ title }}
                v-card-text.text-xs-center
                    h3 {{ message }}
                v-card-actions.pt-0
                    .btns.btns-center
                        v-btn(small color='primary' @click.stop='confirm=false') {{ $t('Cancelar') }}
                        v-btn(large color='accent' :disabled="confirmDisabled" @click="action") {{ confirmState }}
</template>

<script>

    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            message: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                confirm: false,
                confirmLoad: {
                    normal : 'Confirmar',
                    load   : 'Confirmando'
                },
                confirmState : '',
                confirmDisabled: false,
                confirmData: null
            }
        },
        created(){
            this.confirmState = this.confirmLoad.normal
        },
        methods: {
            open(data){
                this.confirm = true
                this.confirmData = data
            },
            close(){
                this.confirm = false
            },
            action(){
                this.$on('playLoader', () => {
                    this.confirmState = this.confirmLoad.load
                    this.confirmDisabled = true
                })
                this.$on('stopLoader', () => {
                    this.confirmState = this.confirmLoad.normal
                    this.confirmDisabled = false
                })
                this.$emit('action', this.confirmData)
            }
        }
    }
    
</script>