<template lang="pug">
    div
        v-snackbar(
        :timeout="30000",
        :top="true"
        :right="true"
        :color="alertColor"
        v-model="alertMsj")
            | {{ alertText }}
            v-btn(flat, dark, @click.native="alertMsj = false") {{ $t('cerrar') }}
        v-dialog(persistent lazy max-width="900px" v-model="modal")
            v-form
                v-card
                    v-card-title {{ $t('Formulario CRM') }}
                    v-card-text
                        template(v-if="loadModal")
                            h3 {{ $t('Cargando...') }}
                        template(v-else)
                            custom-form(
                                ref="customForm"
                                :visibility="false", 
                                :m-custom.sync="model.custom",
                                :m-attribute.sync="model.attribute",
                                custom-form-title='Campos de formulario'
                                :image-selection="false"
                                :required-option="false"
                                :enable-depends="false"
                                @error="customFormError"
                            )
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="modal=false") {{$t('Aceptar')}}
                            v-btn(v-if="!loadModal" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
</template>

<script>
export default {
    data() {
        return {
            modal: false,
            loadingBtn: false,
            loadModal: false,
            modelSchema: {
                networkid   : '',
                custom      : [],
                attribute   : [],
            },
            model: {},
            alertMsj  : false,
            alertColor: '',
            alertText: '',
            current: {},
            formList: []
        }
    },
    created() {
        this.model = this._.cloneDeep(this.modelSchema)
    },
    methods: {
        async open(item) {

            this.current = this._.cloneDeep(item)

            this.model = this._.merge(this._.cloneDeep(this.modelchema), this._.cloneDeep(item))
            this.model.networkid = this.current.uuid

            this.loading = false
            this.modal  = true

            this.loadModal = true
            await this.fetchForm()
            this.loadModal = false
        },
        customFormError(msj){
            this.alertMsj   = true
            this.alertColor = 'error'
            this.alertText  = msj
        },
        fetchForm() {
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/crm/form', {
                        params: { networkid: this.current.uuid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let model = data.data                            
                            this.model = this._.cloneDeep(model)
                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })  
        },
        save() {

            let post = this._.cloneDeep(this.model)
            post.networkid = this.current.uuid
            post.attribute = JSON.stringify(post.attribute)
            post.custom = JSON.stringify(post.custom)

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/crm/form', this.$qs.stringify(post)).then(async (r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.alertMsj = true

                    if(data.response){
                        this.alertColor = 'success'
                        this.alertText = data.message
                        this.loadModal = true
                        await this.fetchForm()
                        this.loadModal = false
                        this.$emit('success')
                    }else{
                        this.alertColor = 'error'
                        this.alertText = data.message
                    }
                }).catch(() => {})
            })
        }
    }
}
</script>