<template lang="pug">
div
    v-snackbar(
    :timeout="30000",
    :top="true"
    :right="true"
    :color="alertColor"
    v-model="alertMsj")
        | {{ alertText }}
        v-btn(flat, dark, @click.native="alertMsj = false") {{ $t('cerrar') }}
    v-dialog(persistent lazy max-width="900px" v-model="modal")
        v-form
            v-card
                v-card-title {{ $t('Editar pregunta') }}
                v-card-text
                    template(v-if="loadModal")
                        h3 {{ $t('Cargando...') }}
                    template(v-else)
                        template(v-if="formList.length")
                            edit-form-data(
                                :custom-form="formList"
                                v-model="model"
                                :required-option="false"
                                :enable-depends="false"
                            )
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="modal=false") {{$t('Aceptar')}}
                        v-btn(v-if="!loadModal" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
</template>

<script>

import EditFormData from 'components/form/FormData'
export default {
    data() {
        return {
            modal: false,
            loadingBtn: false,
            loadModal: false,
            alertMsj  : false,
            alertColor: '',
            alertText: '',
            current: {},
            formList: [],
            model: {}
        }
    },
    created() {
        this.model = {}
    },
    methods: {
        async open(item) {

            this.current = this._.cloneDeep(item)
            this.model = { [this.current.uuid]: item.values }

            this.loading = false
            this.modal  = true

            this.loadModal = true
            await this.fetchFormList()
            this.loadModal = false
        },
        fetchFormList() {
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/crm/form/list', {
                        params: {
                            networkid: this.current.networkid,
                            uuid: this.current.uuid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let formList = data.data                            
                            this.formList = this._.cloneDeep(formList)
                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })  
        },
        save() {

            if (!Object.keys(this.model[this.current.uuid]).length) {
                return
            }

            let post = {}
            post.networkid    = this.current.networkid
            post.custom_id    = this.current.uuid
            post.custom_value = this.model[this.current.uuid]
            post.user_id      = this.current.userid

            if (Array.isArray(post.custom_value)) {
                post.custom_value = JSON.stringify(post.custom_value)
            }

            if (typeof post.custom_value == 'object') {
                post.custom_value = post.custom_value.option
            }

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/crm/form/user', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.alertMsj = true

                    if(data.response){
                        
                        this.alertColor = 'success'
                        this.alertText = data.message
                        this.$emit('success')
                        this.modal = false

                    }else{
                        this.alertColor = 'error'
                        this.alertText = data.message
                    }
                }).catch(() => {})
            })
        }
    },
    components: {
        EditFormData
    }
}
</script>