<template lang="pug">
    .language-box
        h2.mt-2(v-if="showTitle") {{ $t('Traducciones') }}
        v-tabs(
            slot="extension"
            v-model="tab"
            show-arrows)
            v-tabs-slider
            v-tab(
                v-for="(language, i) in languages"
                :key="i"
                :href="'#tab-' + i")
                | {{ language.label }}
        v-tabs-items(v-model="tab")
            v-tab-item(
                v-for="(language, i) in languages"
                :key="i"
                :id="'tab-' + i")
                v-card(flat)
                    v-card-text
                        slot(:lang="language.value" :default="language.default")
</template>

<script>
    export default {
        props: {
            showTitle: {
                type: Boolean,
                default: true
            },
            defaultTab: {
                type: Boolean,
                default: false
            },
            custom: {
                type: Boolean,
                default: false
            },
            language: {
                type: String,
                default: 'es'
            }
        },
        data() {
            return {
                tab: 'tab-0',
                defaultLanguages: [
                    { value: 'pt', label: 'Portugués', default: false },
                    { value: 'en', label: 'Inglés', default: false },
                    { value: 'it', label: 'Italiano', default: false },
                    { value: 'fr', label: 'Francés', default: false },
                    { value: 'de', label: 'Alemán', default: false }
                ]
            }
        },
        computed: {
            languages() {
                
                if(!this.custom){
                    return this.defaultLanguages
                }

                let languages = this._.cloneDeep(this.defaultLanguages)
                languages.unshift({
                    value: 'es', label: 'Español', default: false 
                })

                let language = languages.find((l) => l.value == this.language)
                let idx      = languages.findIndex((l) => l.value == this.language)
                
                language.label = language.label + ' ' + this.$t('(Por defecto)')

                if(idx == 0){
                    languages.shift()
                }else{
                    languages.splice(idx, 1)
                }

                languages.unshift(language)
                return languages
            }
        }
    }

</script>