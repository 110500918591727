<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="textColor"
        v-model="textMsj")
            | {{ textText }}
            v-btn(flat dark @click.native="textMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="text" persistent)
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="text=false")
                            v-icon close
                    h3.full-width {{ $t('Red') }} {{ current.name }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        h2.mb-2 {{ $t('Confirmación de WhatsApp') }}
                        v-layout.mt-2(row wrap)
                            v-flex(xs12)
                                v-data-table(
                                    hide-actions
                                    :items="items" 
                                    :headers="headers"
                                )
                                    template(slot='headers' slot-scope='props')
                                        tr
                                            th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                span.subheading {{ $t(header.text) }}
                                    template(slot="items" slot-scope="props")
                                        td {{ props.item.variable }}
                                        td.py-2(v-if="props.item.variable == '{custom|id}'") 
                                            | {{ $t(props.item.description) }}
                                            v-select(clearable v-model="customid" :items="customForm" item-text="placeholder" item-value="uuid")
                                            span id: {{ customid || $t('Seleccione un campo') }}
                                        td(v-else) {{ $t(props.item.description) }}
                        language(custom :language="current.language" :show-title="false")
                            template(slot="default" slot-scope="props")
                                v-layout.mt-2(row wrap)
                                    v-flex(xs12): h3 {{ $t('Textos de la ventana de confirmación') }}
                                    v-flex(xs12)
                                        v-text-field(v-if="props.default" v-model="model.modaltitle" :label="$t('Título de la ventana')")
                                        v-text-field(v-else v-model="model.translation[props.lang].modaltitle" :label="$t('Título de la ventana')")
                                    v-flex(xs12)
                                        v-text-field(v-if="props.default" multi-line rows="3" v-model="model.modaltext" :label="$t('Texto de la ventana')")
                                        v-text-field(v-else multi-line rows="3" v-model="model.translation[props.lang].modaltext" :label="$t('Texto de la ventana')")
                                    v-flex(xs12)
                                        v-text-field(v-if="props.default" v-model="model.modalbtn" :label="$t('Botón de la ventana')")
                                        v-text-field(v-else v-model="model.translation[props.lang].modalbtn" :label="$t('Botón de la ventana')")
                                    v-flex.mt-3(xs12): h3 {{ $t('Texto de confirmación en WhatsApp') }}
                                    v-flex(xs12 sm6)
                                        v-text-field(v-if="props.default" multi-line v-model="model.confirmtext" :label="$t('Confirmación de WhatsApp')")
                                        v-text-field(v-else multi-line v-model="model.translation[props.lang].confirmtext" :label="$t('Confirmación de WhatsApp')")
                                    v-flex(xs12 sm6)
                                        whatsapp-preview(:text="props.default ? model.confirmtext : model.translation[props.lang].confirmtext")
                        h2.mb-2 {{ $t('Confirmación de WhatsApp (Encuestas)') }}
                        v-layout.mt-2(row wrap)
                            v-flex(xs12)
                                v-data-table(
                                    hide-actions
                                    :items="sitems" 
                                    :headers="headers"
                                )
                                    template(slot='headers' slot-scope='props')
                                        tr
                                            th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                span.subheading {{ $t(header.text) }}
                                    template(slot="items" slot-scope="props")
                                        td {{ props.item.variable }}
                                        td {{ $t(props.item.description) }}
                        language(custom :language="current.language" :show-title="false")
                            template(slot="default" slot-scope="props")
                                v-layout.mt-2(row wrap)
                                    v-flex.mt-3(xs12): h3 {{ $t('Texto de confirmación en WhatsApp (Encuestas)') }}
                                    v-flex(xs12 sm6)
                                        v-text-field(v-if="props.default" multi-line v-model="model.sconfirmtext" :label="$t('Confirmación de WhatsApp')")
                                        v-text-field(v-else multi-line v-model="model.translation[props.lang].sconfirmtext" :label="$t('Confirmación de WhatsApp')")
                                    v-flex(xs12 sm6)
                                        whatsapp-preview(:text="props.default ? model.sconfirmtext : model.translation[props.lang].sconfirmtext")
                        h2.mb-2 {{ $t('Enlace de invitación') }}
                        v-layout.mt-2(row wrap)
                            v-flex(xs12)
                                v-data-table(
                                    hide-actions
                                    :items="shareitems" 
                                    :headers="headers"
                                )
                                    template(slot='headers' slot-scope='props')
                                        tr
                                            th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                span.subheading {{ $t(header.text) }}
                                    template(slot="items" slot-scope="props")
                                        td {{ props.item.variable }}
                                        td {{ $t(props.item.description) }}
                        language(custom :language="current.language" :show-title="false")
                            template(slot="default" slot-scope="props")
                                v-layout.mt-2(row wrap)
                                    v-flex.mt-3(xs12): h3 {{ $t('Enlace de invitación') }}
                                    v-flex(xs12 sm6)
                                        v-text-field(v-if="props.default" multi-line v-model="model.sharetext" :label="$t('Enlace de invitación')")
                                        v-text-field(v-else multi-line v-model="model.translation[props.lang].sharetext" :label="$t('Enlace de invitación')")
                                    v-flex(xs12 sm6)
                                        whatsapp-preview(:text="props.default ? model.sharetext : model.translation[props.lang].sharetext")

                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="text=false") {{$t('Aceptar')}}
                        v-btn(v-if="!preload" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
</template>

<script>

import Vue from 'vue'
export default {
    data(){
        return {
            text: false,
            textMsj   : false,
            textColor : '',
            textText  : '',
            current: {},
            customid: '',
            customForm: [],
            modelSchema: {
                modaltitle   : '',
                modaltext    : '',
                modalbtn     : '',
                confirmtext  : '',
                sconfirmtext: '',
                sharetext: '',
                translation  : {}
            },
            model: {},
            headers: [
                {
                    text  : 'Variable',
                    value : 'variable',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Descripción',
                    value : 'description',
                    sortable: false,
                    align: 'left'
                }
            ],
            items: [
                {
                    variable: '{names}',
                    description: 'Nombre completo del usuario'
                },
                {
                    variable: '{name}',
                    description: 'Nombre del usuario'
                },
                {
                    variable: '{surname}',
                    description: 'Primer apellido del usuario'
                },
                {
                    variable: '{surname2}',
                    description: 'Segundo apellido del usuario'
                },
                {
                    variable: '{refer}',
                    description: 'Nombre del referidor'
                },
                {
                    variable: '{network}',
                    description: 'Nombre de la red'
                },
                {
                    variable: '{country}',
                    description: 'Nombre del país'
                },
                {
                    variable: '{state}',
                    description: 'Nombre del departamento/estado/provincia'
                },
                {
                    variable: '{city}',
                    description: 'Nombre de la ciudad'
                },
                {
                    variable: '{zones}',
                    description: 'Listado de las zonas separado por comas'
                },
                {
                    variable: '{custom|id}',
                    description: 'Campo de formulario personalizado'
                },
                {
                    variable: '{inviteurl}',
                    description: 'Enlace de invitación'
                },
                {
                    variable: '{shorturl}',
                    description: 'Enlace de la red personalizado'
                },
                {
                    variable: '{code}',
                    description: 'Código de referido'
                },
                {
                    variable: '{wpgroup}',
                    description: 'Nombre del grupo de WhatsApp'
                },
                {
                    variable: '{wpgroupurl}',
                    description: 'Enlace del grupo de WhatsApp'
                }
            ],
            sitems: [
                {
                    variable: '{name}',
                    description: 'Nombre de la encuesta'
                },
                {
                    variable: '{starttext}',
                    description: 'Texto inicial'
                },
                {
                    variable: '{endtext}',
                    description: 'Texto final'
                },
                {
                    variable: '{results}',
                    description: 'Resultados de la encuesta'
                },
                {
                    variable: '{wpgroup}',
                    description: 'Nombre del grupo de WhatsApp'
                },
                {
                    variable: '{wpgroupurl}',
                    description: 'Enlace del grupo de WhatsApp'
                }
            ],
            shareitems: [
                {
                    variable: '{current_names}',
                    description: 'Nombre del usuario actual'
                },
                {
                    variable: '{names}',
                    description: 'Nombre completo del usuario'
                },
                {
                    variable: '{name}',
                    description: 'Nombre del usuario'
                },
                {
                    variable: '{surname}',
                    description: 'Primer apellido del usuario'
                },
                {
                    variable: '{surname2}',
                    description: 'Segundo apellido del usuario'
                },
                {
                    variable: '{refer}',
                    description: 'Nombre del referidor'
                },
                {
                    variable: '{network}',
                    description: 'Nombre de la red'
                },
                {
                    variable: '{country}',
                    description: 'Nombre del país'
                },
                {
                    variable: '{state}',
                    description: 'Nombre del departamento/estado/provincia'
                },
                {
                    variable: '{city}',
                    description: 'Nombre de la ciudad'
                },
                {
                    variable: '{zones}',
                    description: 'Listado de las zonas separado por comas'
                },
                {
                    variable: '{inviteurl}',
                    description: 'Enlace de invitación'
                },
                {
                    variable: '{code}',
                    description: 'Código de referido'
                },
                {
                    variable: '{wpgroup}',
                    description: 'Nombre del grupo de WhatsApp'
                },
                {
                    variable: '{wpgroupurl}',
                    description: 'Enlace del grupo de WhatsApp'
                }
            ],
            preload: false,
            loadingBtn: false
        }
    },
    created(){
        this.setTranslation()
        this.model = this._.cloneDeep(this.modelSchema)
    },
    methods: {
        setTranslation(){

            let tl  = {}

            for(let lang of this.$root.languages){

                tl[lang] = {
                    confirmtext: '',
                    modaltitle : '',
                    modaltext  : '',
                    modalbtn   : '',
                    sconfirmtext: ''
                }
            }

            Vue.set(this.modelSchema, 'translation', tl)
        },
        async open(item){

            this.current = this._.cloneDeep(item)
            this.text = true
            this.preload = true

            this.customForm = []
            
            await this.fetch()
            await this.fetchCustomForm()
            
            this.preload = false
        },
        fetchCustomForm(){

            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: { networkid: this.current.uuid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let model = data.data
                            model.custom = model.custom.map((c) => {
                                return {
                                    uuid: c.uuid,
                                    placeholder: c.placeholder
                                }
                            })
                            
                            this.customForm = this._.cloneDeep(model.custom)
                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetch(){
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/network/text', {
                        params: { networkid: this.current.uuid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let model = data.data
                            this.model = this._.merge(this.model, model)
                            this.setDefaultTranslation()

                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        save(){

            this.setDefaultTranslation()

            let post = this._.cloneDeep(this.model)
            post.translation = JSON.stringify(post.translation)
            post.networkid = this.current.uuid

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/network/text', this.$qs.stringify(post)).then(async (r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.textMsj = true

                    if(data.response){
                        
                        this.textColor = 'success'
                        this.textText = data.message

                        this.setTranslation()
                        this.model = this._.cloneDeep(this.modelSchema)
                        
                        this.preload = true
                        await this.fetch()
                        await this.fetchCustomForm()
                        this.preload = false

                    }else{
                        this.textColor = 'error'
                        this.textText = data.message
                    }
                }).catch(() => {})
            })
        },
        
        //Establecer idioma por defecto
        setDefaultTranslation(){

            let tl = this.model.translation.hasOwnProperty(this.current.language) ? this.model.translation[this.current.language] : {}
            if(Object.keys(tl).length){

                this.model.confirmtext  = 'confirmtext' in tl ? tl.confirmtext: ''
                this.model.modaltitle   = 'modaltitle' in tl ? tl.modaltitle  : ''
                this.model.modaltext    = 'modaltext' in tl ? tl.modaltext    : ''
                this.model.modalbtn     = 'modalbtn' in tl ? tl.modalbtn      : ''
                this.model.sconfirmtext = 'sconfirmtext' in tl ? tl.sconfirmtext : ''
                this.model.sharetext    = 'sharetext' in tl ? tl.sharetext : ''
            }
        }
    }
 }

</script>