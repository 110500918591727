<template lang="pug">
    infinite.infinite-loading(:on-infinite="infinite", ref="infinite")
        span(slot="no-more")
        span(slot="no-results")
        div(slot="spinner")
            v-progress-circular(indeterminate color="primary")
</template>

<script>

    import Infinite from 'components/vendor/InfiniteLoading'

    export default {
        props: {
            infinite: {
                type: Function,
                default(){
                    return {}
                }
            }
        },
        components: {
            infinite: Infinite
        },
        methods: {
            loaded(){
                this.$refs.infinite && this.$refs.infinite.$emit('$InfiniteLoading:reset')
            },
            reset(){
                this.$refs.infinite && this.$refs.infinite.$emit('$InfiniteLoading:loaded')
            },
            complete(){
                this.$refs.infinite && this.$refs.infinite.$emit('$InfiniteLoading:complete')
            }
        }
    }

</script>