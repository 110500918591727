<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="alertColor"
            v-model="alert")
                | {{ alertText }}
                v-btn(flat, dark, @click.native="alert = false") {{$t('cerrar')}}

        template(v-if="value == 2 && isUserRequest")

            //- Aceptar solicitud de amistad
            v-btn(color="primary" :loading="loader" small @click="state(1)") {{ $t('Aceptar solicitud de amistad') }}

            //- Solicitud pendiente
            v-btn(color="gray" :loading="loaderCancel" small @click="cancelFriend") {{ $t('Cancelar solicitud de amistad') }}

        template(v-else)

            //- Estado inicial
            v-btn(v-if="value == 0" color="primary" :loading="loader" small @click="addFriend") {{ $t('Agregar amigo') }}

            //- Solicitud aceptada
            v-btn(v-if="value == 1" color="gray" :loading="loader" small @click="$refs.delete.open(user)") {{ $t('Eliminar de mis amigos') }}

            //- Solicitud pendiente
            v-btn(v-if="value == 2" color="gray" :loading="loaderCancel" small @click="cancelFriend") {{ $t('Cancelar solicitud de amistad') }}

        confirm(ref="delete" @action="deleteFriend" :title="$t('Eliminar de mis amigos')" :message="$t('¿Estás seguro de eliminar este amigo?')")
</template>
<script>
    export default {
        props:{
            user:{
                type: Object,
                required: true
            },
            requestId: {
                type: Object,
                default(){
                    return {}
                }
            },
            value: ''
        },
        data(){
            return {
                alert        : false,
                alertText    : '',
                alertColor   : '',
                loader       : false,
                loaderCancel : false
            }
        },
        computed: {
            isUserRequest(){
                return this.user.uuid == (this.requestId != null ? this.requestId.friendid : null)
            }
        },
        methods:{

            state(state){

                this.loader = true
                this.$api(this, (xhr) => {
                    xhr.post('/social/friend_request/state', this.$qs.stringify({
                        state,
                        uuid: this.requestId.uuid
                    })).then((r) => {
                        
                        let data = r.data
                        this.loader = false

                        if(data.response){

                            this.$root.$emit('accept_friend_request_total', {
                                requestid: this.requestId.uuid
                            })

                            this.$emit('accepted')
                        }

                    }).catch(() =>this.loader = false)
                })
            },

            // Agregar amigo
            addFriend(){

                this.loader = true
                this.$api(this, (xhr) => {
                    xhr.post('/social/friend/add', this.$qs.stringify({
                        userid: this.user.uuid
                    })).then((r) => {

                        let data    = r.data
                        this.loader = false
                        this.alert  = true
                        this.alertText  = this.$t(data.message)

                        if(data.response){

                            this.alertColor = 'success'

                            // Estado pendiente
                            this.$emit('input', 2)

                        }else{
                            this.alertColor = 'error'
                        }
                    }).catch(() => {})
                })
            },

            // Cancelar solicitud de amistad
            cancelFriend(){

                this.loaderCancel = true
                this.$api(this, (xhr) => {
                    xhr.post('/social/friend/cancel', this.$qs.stringify({
                        userid: this.user.uuid
                    })).then((r) => {

                        let data          = r.data
                        this.loaderCancel = false
                        this.alert        = true
                        this.alertText    = this.$t(data.message)

                        if(data.response){

                            this.alertColor = 'success'
                            
                            // Estado inicial
                            this.$emit('input', 0)

                        }else{
                            this.alertColor = 'error'
                        }
                    }).catch(() => {})
                })
            },

            // Eliminar amistad
            deleteFriend(data){

                this.$refs.delete.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.post('/social/friend/delete', this.$qs.stringify({
                        userid: data.uuid
                    })).then((r) => {

                        let data = r.data
                        this.$refs.delete.$emit('stopLoader')
                        this.alert  = true
                        this.alertText = this.$t(data.message)

                        if(data.response){

                            this.alertColor = 'success'
                            
                            // Estado inicial
                            this.$emit('input', 0)
                            this.$refs.delete.close()

                        }else{
                            this.alertColor = 'error'
                        }

                    }).catch(() => {
                        this.$refs.delete.$emit('stopLoader')
                    })
                })
            }
        }
    }
</script>