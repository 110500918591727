import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from 'src/config'
import axios from 'axios'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  silentTranslationWarn: true
})

export function loadLang(lang) {

  return new Promise((resolve) => {

    axios.get(config.apiurl + 'i18n/' + lang + '.json').then((res) => {
      
      // Fixed NORMALIZED UTF8 NFC
      let normalizeData = {}
      for (let dat in res.data) {
        normalizeData[dat.normalize('NFC')] = res.data[dat].normalize('NFC')  
      }

      i18n.setLocaleMessage(lang, normalizeData)
      resolve()

    }).catch(() => resolve())
  })
}