import config from 'src/config'
const APIURL = config.apiurl

let api = async (vue, cb) => {

    //token de sesión
    let token = await vue.$getItem('xtoken')
    token = token ? token : ''

    return cb(vue.$axios.create({
        baseURL: APIURL,
        headers: {
            common: {
                'Accept': 'application/json',
                'Accept-Language' : vue.$root.currentLanguage,
                [config.xtoken]: token,
                'Wappid-Network': vue.$root.currentNetwork ? (vue.$root.currentNetwork.proxied ? vue.$root.currentNetwork.proxied : '') : ''
            },
            post: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        },
        validateStatus(status) {
            return status < 500
        }
    }))
}

let http = (vue, cb) => {
    return cb(vue.$axios.create({
        validateStatus(status) {
            return status < 500
        }
    }))
}

export {api, http, APIURL}