<template lang="pug">
    div
        v-dialog(lazy max-width="600px" v-model="goal")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="goal=false")
                            v-icon close
                    h3.full-width(v-if="$root.profile.uuid == genesisNode.uuid") {{ $t('Mis retos') }}
                    h3.full-width(v-else) {{ $t('Retos') }}
                    h5.full-width {{genesisNode.names || genesisNode.name}} - {{genesisNode.phone}}
                v-card-text.pt-2
                    p.my-2(v-if="$root.profile.uuid == genesisNode.uuid")
                        | {{ $t("Cumple los siguientes retos diarios, semanales y mensuales de referidos en la red ${genesisNode.network} invitando a tus amigos y amigas de whatsapp y de tus redes sociales") }} 
                        router-link(to="/share" tag="a") {{ $t('aquí') }}
                    v-layout.mt-3.mb-2(row wrap)
                        v-flex(xs6 sm4 v-for="(goal, i) in goals" :key="'goal' + i")
                            h4.mb-2.text-xs-center {{ goal.name }}
                            v-progress-circular.text-xs-center.goal-bar(
                                :rotate="360"
                                :size="80"
                                :width="5"
                                :value="goal.value"
                                :color="goal.value < 100 ? 'red' : 'green'"
                            )
                                b {{ goal.value }}%
                            .text-xs-center.mt-2
                                small(v-if="!goal.direct") {{ $t('Invitados registrados')}}: {{ goal.total }}
                                small(v-else) {{$t('Invitados directos')}}: {{ goal.total }}
                                br
                                small {{ $t('Meta de invitación') }}: {{ goal.goal }}
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="goal=false") {{ $t('Aceptar') }}
</template>

<script>
export default {
    data(){
        return {
            goal: false,
            preloadRank: false,
            loadingCurrentNode: false,
            genesisNode: {},
            currentNode: {},
            genesis: true,
            items: [],
            goals: []
        }
    },
    methods:{

        open(item){

            let goal  = []
            this.goal = true

            this.genesisNode = this._.cloneDeep(item)
            this.currentNode = this._.cloneDeep(item)

            let goals = {
                total_day    : item.total.total_day,
                total_week   : item.total.total_week,
                total_month  : item.total.total_month,
                stotal_day   : item.total.stotal_day,
                stotal_week  : item.total.stotal_week,
                stotal_month : item.total.stotal_month
            }

            if(item.goal.hasOwnProperty('day')){
                goal = [
                    {
                        name  : this.$t('Día'),
                        value : Math.round(item.goal.day > 0 ? (goals.total_day/item.goal.day)*100 : 0),
                        total : goals.total_day,
                        goal  : item.goal.day,
                        direct: false
                    },
                    {
                        name  : this.$t('Semana'),
                        value : Math.round(item.goal.week > 0 ? (goals.total_week/item.goal.week)*100 : 0),
                        total : goals.total_week,
                        goal  : item.goal.week,
                        direct: false
                    },
                    {
                        name  : this.$t('Mes'),
                        value : Math.round(item.goal.month > 0 ?(goals.total_month/item.goal.month)*100 : 0),
                        total : goals.total_month,
                        goal  : item.goal.month,
                        direct: false
                    },
                    {
                        name  : this.$t('Día'),
                        value : Math.round(item.goal.sday > 0 ? (goals.stotal_day/item.goal.sday)*100 : 0),
                        total : goals.stotal_day,
                        goal  : item.goal.sday,
                        direct: true
                    },
                    {
                        name  : this.$t('Semana'),
                        value : Math.round(item.goal.sweek > 0 ? (goals.stotal_week/item.goal.sweek)*100 : 0),
                        total : goals.stotal_week,
                        goal  : item.goal.sweek,
                        direct: true
                    },
                    {
                        name  : this.$t('Mes'),
                        value : Math.round(item.goal.smonth > 0 ? (goals.stotal_month/item.goal.smonth)*100 : 0),
                        total : goals.stotal_month,
                        goal  : item.goal.smonth,
                        direct: true
                    }
                ]
            }

            this.goals = goal
        }
    }
}

</script>