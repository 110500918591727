import qs from 'querystring'
import { api } from 'scripts/api'
import uuidv4 from 'uuid/v4'

export default {

    install(Vue) {
        
        if (!Vue.prototype.hasOwnProperty('$qs')) {
            Object.defineProperty(Vue.prototype, '$qs', {
                value: qs
            });
        }

        if (!Vue.prototype.hasOwnProperty('$api')) {
            Object.defineProperty(Vue.prototype, '$api', {
                value: api
            });
        }

        if (!Vue.prototype.hasOwnProperty('$uuid')) {
            Object.defineProperty(Vue.prototype, '$uuid', {
                value: uuidv4
            });
        }
    }
}