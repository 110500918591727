<template lang="pug">
    div
        v-toolbar.header(dark fixed app height="56")  
            v-toolbar-side-icon(@click.stop="drawer = !drawer")
            v-toolbar-title
                template(v-if="$root.hasCurrentNetwork")
                    img.mt-2(v-if="!$root.currentNetwork.custom_domain" height="70" src="/public/img/logo.png" :alt="$root.currentNetwork.name")
                img.mt-2(v-else height="70" src="/public/img/logo.png" alt="Wappid")
            v-spacer
            template(v-if="$root.profile.type == 1")
                friend-request-bar
                chat-bar
            notification-bar(v-if="$root.profile.type != 5")
            div.d-block.notification-bar(slot="extension")
            v-menu(offset-y origin="top right" nudge-top="0")
                .user.hidden-sm-and-down(v-for="(data, $i) in profile" :key="$i" slot="activator")
                    .user-content
                        strong {{ data.name }}
                        small {{ data.email }}
                    v-icon keyboard_arrow_down
                    .user-avatar
                        img(v-if="photourl.length" :src="photourl")
                        v-gravatar(v-else :email="data.email")
                v-list.secondary.pa-0
                    v-list-tile.white--text(v-if="$root.profile.type == 2" @click.native="$router.push({path:'/plan'})" ripple)
                        v-list-tile-action
                            v-icon.white--text schedule
                        v-list-tile-content
                            v-list-tile-title.white--text {{ $t('Mi plan') }}
                    //- Referidor
                    v-list-tile.white--text(v-if="$root.profile.type == 1" @click.native="$router.push({path:'/account'})" ripple)
                        v-list-tile-action
                            v-icon.white--text account_box
                        v-list-tile-content
                            v-list-tile-title.white--text {{ $t('Mi cuenta') }}
                    //- Subadministrador
                    v-list-tile.white--text(v-if="$root.profile.type != 1 && $root.profile.type != 3 && $root.profile.type != 5" @click.native="$router.push({path:'/s-account'})" ripple)
                        v-list-tile-action
                            v-icon.white--text account_box
                        v-list-tile-content
                            v-list-tile-title.white--text {{ $t('Mi cuenta') }}
                    //- Callcenter
                    v-list-tile.white--text(v-if="$root.profile.type == 3 || $root.profile.type == 5" @click.native="$router.push({path:'/c-account'})" ripple)
                        v-list-tile-action
                            v-icon.white--text account_box
                        v-list-tile-content
                            v-list-tile-title.white--text {{ $t('Mi cuenta') }}
                    v-list-tile.white--text(@click.native="logout" ripple)
                        v-list-tile-action
                            v-icon.white--text exit_to_app
                        v-list-tile-content
                            v-list-tile-title.white--text {{ $t('Cerrar sesión') }}
            slot
        v-navigation-drawer(fixed v-model="drawer" app)
            v-toolbar(flat color="secondary" height="100")
                v-list.pa-0.py-2
                    v-list-tile(avatar v-for="(data, $i) in profile" :key="$i")
                        v-list-tile-avatar(:size="40")
                            img(v-if="photourl.length" :src="photourl")
                            v-gravatar(v-else :email="data.email")
                        v-list-tile-content
                            v-list-tile-title.white--text.userMenu
                                h3 {{ data.name }}
                            v-list-tile-sub-title.white--text(style="max-width:80%;") 
                                v-tooltip
                                    span(slot="activator") {{ data.email }}
                                    span {{ data.email }}
                    v-list-tile(v-if="$root.profile.type == 1")
                        div.badges
                            v-icon(v-for="(badge, i) in $root.profile.badges" :key="'badge'+i" small :color="badge.color") verified
            v-list.pt-0(dense='')
                v-list-tile(class="secondary white--text" @click.native="logout")
                    v-list-tile-action
                        v-icon.white--text exit_to_app
                    v-list-tile-content
                        v-list-tile-title.white--text {{ $t('Cerrar sesión') }}
                v-list-tile(v-if="$root.profile.type == 2" class="secondary white--text" @click.native="$router.push({path:'/plan'})")
                    v-list-tile-action
                        v-icon.white--text schedule
                    v-list-tile-content
                        v-list-tile-title.white--text {{ $t('Mi plan') }}
                v-list-tile.pb-2(class="secondary white--text" v-if="$root.profile.type == 1" @click.native="$router.push({path:'/account'})")
                    v-list-tile-action
                        v-icon.white--text account_box
                    v-list-tile-content
                        v-list-tile-title.white--text {{ $t('Mi cuenta') }}
                v-list-tile.pb-2(class="secondary white--text" v-if="$root.profile.type != 1 && $root.profile.type != 3 && $root.profile.type != 5" @click.native="$router.push({path:'/s-account'})")
                    v-list-tile-action
                        v-icon.white--text account_box
                    v-list-tile-content
                        v-list-tile-title.white--text {{ $t('Mi cuenta') }}
                v-list-tile.pb-2(class="secondary white--text" v-if="$root.profile.type == 3 || $root.profile.type == 5" @click.native="$router.push({path:'/c-account'})")
                    v-list-tile-action
                        v-icon.white--text account_box
                    v-list-tile-content
                        v-list-tile-title.white--text {{ $t('Mi cuenta') }}
                v-divider
                template(v-if="$parent.isadmin && $root.profile.type == 0")
                    v-list-tile(v-for='(link, $i) in menuAdmin' :key='$i' :to='link.to')
                        v-list-tile-action
                            v-icon {{ link.icon }}
                        v-list-tile-content
                            v-list-tile-title {{ $t(link.title) }}
                    v-list-tile.overflow-visible(to="/pending-note")
                        v-list-tile-action
                            v-icon question_answer
                        v-list-tile-content
                            v-list-tile-title
                                v-badge(color="red" right overlap)
                                    span(slot="badge") {{ $root.profile.pending_notes_total.total }}
                                    span {{ $t('Conversaciones pendientes') }}
                template(v-else-if="$parent.issubadmin && $root.profile.type == 2")
                    v-list-tile(v-for='(link, $i) in menuSubadmin' :key='$i' :to='link.to')
                        v-list-tile-action
                            v-icon {{ link.icon }}
                        v-list-tile-content
                            v-list-tile-title {{ $t(link.title) }}
                    v-list-tile(to="/pending-note")
                        v-list-tile-action
                            v-icon question_answer
                        v-list-tile-content
                            v-list-tile-title
                                v-badge(color="red" right inline)
                                    span(slot="badge") {{ $root.profile.pending_notes_total.total }}
                                    span {{ $t('Conversaciones pendientes') }}
                template(v-else-if="$parent.iscallcenter && $root.profile.type == 3")
                    v-list-tile(v-for='(link, $i) in menuCallcenter' :key='$i' :to='link.to')
                        v-list-tile-action
                            v-icon {{ link.icon }}
                        v-list-tile-content
                            v-list-tile-title {{ $t(link.title) }}
                template(v-else-if="$parent.istranslator && $root.profile.type == 5")
                    v-list-tile(v-for='(link, $i) in menuTranslator' :key='$i' :to='link.to')
                        v-list-tile-action
                            v-icon {{ link.icon }}
                        v-list-tile-content
                            v-list-tile-title {{ $t(link.title) }}
                template(v-else)
                    template(v-for='(link, $i) in menu')
                        v-list-tile(v-if="link.hasOwnProperty('click')" @click="handleClick(link)")
                            v-list-tile-action
                                v-icon {{ link.icon }}
                            v-list-tile-content
                                v-list-tile-title(v-if="load(link)") {{ $t('Cargando') }}...
                                v-list-tile-title(v-else) {{ $t(link.title) }}
                        v-list-tile(v-else :to='link.to')
                            v-list-tile-action
                                v-icon {{ link.icon }}
                            v-list-tile-content
                                v-list-tile-title {{ $t(link.title) }}
                    v-list-tile(to="/friend")
                        v-list-tile-action
                            v-icon groups
                        v-list-tile-content
                            v-list-tile-title
                                span {{ $t('Amigos')}}
                    v-list-tile(to="/chat")
                        v-list-tile-action
                            v-icon chat
                        v-list-tile-content
                            v-list-tile-title
                                span {{ $t('Mensajes')}}
                    template(v-if="$root.profile.hasOwnProperty('mynetwork') && $root.profile.mynetwork.length")
                        v-list-tile.mt-2
                            v-list-tile-content
                                v-list-tile-title: h3 {{$t('Mis redes')}}
                        v-list-tile.network-tile(v-for="(network, i) in $root.profile.mynetwork" :key="'mynetwork'+i")
                            v-list-tile-avatar
                                img(v-if="shareAvatarUrl(network).length" :src="shareAvatarUrl(network)")
                                v-gravatar(v-else :email="network.alias")
                            v-list-tile-content
                                v-list-tile-title.cur-p(style="text-decoration:underline;" @click="goNetwork(network)") {{ network.name }}
                    template(v-if="$root.profile.hasOwnProperty('subnetworks') && $root.profile.subnetworks.length")
                        v-list-tile.mt-2
                            v-list-tile-content
                                v-list-tile-title: h3 {{$t('Redes disponibles')}}
                        v-list-tile.network-tile(v-for="(network, i) in $root.profile.subnetworks" :key="'subnetwork'+i")
                            v-list-tile-avatar
                                img(v-if="shareAvatarUrl(network).length" :src="shareAvatarUrl(network)")
                                v-gravatar(v-else :email="network.alias")
                            v-list-tile-content
                                v-list-tile-title.cur-p(v-if="network.registered" style="height:38px;text-decoration:underline;")
                                    | {{ network.name }}
                                v-list-tile-title(v-else style="height:38px;")
                                    | {{ network.name }}
                                    v-btn.primary(v-if="!network.registered" small depressed round @click.prevent="registerNetwork(network)" :loading="network.loader") {{$t('Unirme')}}
        rank(ref="rank")
        goal(ref="goal")
</template>

<script>

    import config from 'src/config'
    export default {
        data() {
            return {
                drawer: false,
                loadRank: false,
                loadGoal: false,
                menuAdmin: [
                    { 
                        title: 'Reportes', 
                        icon: 'dashboard',
                        to: "/dashboard" },
                    { 
                        title: 'Posiciones', 
                        icon: 'poll', 
                        to: "/ranking" },
                    { 
                        title: 'Redes', 
                        icon: 'poll', 
                        to: "/networks" 
                    },
                    { 
                        title: 'Configuración', 
                        icon: 'settings', 
                        to: "/settings" 
                    },
                    { 
                        title: 'Traducciones',
                        icon: 'g_translate', 
                        to: "/translate"
                    },
                    { 
                        title: 'Galería', 
                        icon: 'image', 
                        to: "/gallery" 
                    },
                    { 
                        title: 'Causas', 
                        icon: 'image', 
                        to: "/banner" 
                    },
                    { 
                        title: 'Líderes', 
                        icon: 'group', 
                        to: "/leader" 
                    },
                    { 
                        title: 'Regiones', 
                        icon: 'map', 
                        to: "/region" 
                    },
                    { 
                        title: 'Transacciones', 
                        icon: 'schedule', 
                        to: "/transaction" 
                    },
                    { 
                        title: 'Subadministradores', 
                        icon: 'account_box', 
                        to: "/subadmin" 
                    },
                    { 
                        title: 'Usuarios', 
                        icon: 'group', 
                        to: "/user" 
                    },
                    { 
                        title: 'Encuestas', 
                        icon: 'assignment_turned_in', 
                        to: "/survey" 
                    },
                    {
                        title: 'WhatsApp',
                        icon: 'chat', 
                        to: "/whatsapp",
                    },
                    {
                        title: 'Sincronizar contactos', 
                        icon: 'sync', 
                        to: "/sync-contact" 
                    },
                    {
                        title: 'Usuarios personalización de redes',
                        icon: 'group', 
                        to: "/custom-network-users",
                    },
                    {
                        title: 'Casas de registro',
                        icon: 'add_home', 
                        to: "/register-stand-admin",
                    },
                    {
                        title: 'Aprobar casas de registro',
                        icon: 'home_filled', 
                        to: "/stand",
                    }
                ],
                menuSubadmin: [
                    { 
                        title: 'Reportes', 
                        icon: 'dashboard',
                        to: "/dashboard" },
                    { 
                        title: 'Posiciones', 
                        icon: 'poll', 
                        to: "/ranking" },
                    { 
                        title: 'Redes', 
                        icon: 'poll', 
                        to: "/networks" 
                    },
                    { 
                        title: 'Líderes', 
                        icon: 'group', 
                        to: "/leader" 
                    },
                    { 
                        title: 'Galería', 
                        icon: 'image', 
                        to: "/gallery" 
                    },
                    { 
                        title: 'Causas',
                        icon: 'image', 
                        to: "/banner" 
                    },
                    { 
                        title: 'Encuestas', 
                        icon: 'assignment_turned_in', 
                        to: "/survey" 
                    },
                    {
                        title: 'WhatsApp',
                        icon: 'chat', 
                        to: "/whatsapp",
                    },
                    { 
                        title: 'Sincronizar contactos', 
                        icon: 'sync', 
                        to: "/sync-contact" 
                    },
                    {
                        title: 'Usuarios personalización de redes',
                        icon: 'group', 
                        to: "/custom-network-users",
                    },
                    {
                        title: 'Casas de registro',
                        icon: 'add_home', 
                        to: "/register-stand-admin",
                    },
                    {
                        title: 'Aprobar casas de registro',
                        icon: 'home_filled', 
                        to: "/stand",
                    }
                ],
                menuCallcenter: [
                    { 
                        title: 'Usuarios', 
                        icon: 'group',
                        to: "/callcenter" 
                    },
                    { 
                        title: 'Resultados', 
                        icon: 'analytics',
                        to: "/callcenter-result" 
                    }
                ],
                menuTranslator: [
                    { 
                        title: 'Traducciones',
                        icon: 'g_translate', 
                        to: "/translate"
                    },
                ]
            }
        },
        computed: {

            menu(){
                
                let menu = [
                    { 
                        title: 'Inicio', 
                        icon: 'home', 
                        to: "/home",
                    },
                    { 
                        title: 'Mis invitados', 
                        icon: 'poll', 
                        to: "/stats",
                    },
                    { 
                        title : 'Mis rankings',
                        icon  : 'star',
                        click : 'openRank',
                        load  : 'loadRank'
                    },
                    { 
                        title: 'Compartir', 
                        icon: 'share', 
                        to: "/share",
                    },
                    { 
                        title: 'Mi galería', 
                        icon: 'image', 
                        to: "/gallery-s",
                    }
                ]

                if(this.$root.profile.network && this.$root.profile.network.isgoal && this.$root.profile.isgoal){
                    menu.splice(3, 0, { 
                        title : 'Mis retos',
                        icon  : 'stars',
                        click : 'openGoal',
                        load  : 'loadGoal'
                    })
                }

                // Email o comentarista
                if (('email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1) || 
                ('email_comment' in this.$root.profile.network && this.$root.profile.network.email_comment.indexOf(this.$root.profile.email) != -1)) {
                    menu.push({
                        title: 'Posiciones de la red', 
                        icon: 'poll', 
                        to: "/ranking-s",
                    })
                }

                if('email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1){

                    menu.push({ 
                        title: 'Sincronizar contactos', 
                        icon: 'sync', 
                        to: "/sync-contact" 
                    })

                    menu.push({
                        title: 'Líderes', 
                        icon: 'group', 
                        to: "/leader-s",
                    })

                    menu.push({
                        title: 'Encuestas', 
                        icon: 'assignment_turned_in', 
                        to: "/survey",
                    })

                    menu.push({
                        title: 'WhatsApp',
                        icon: 'chat', 
                        to: "/whatsapp",
                    })
                }

                // Administrador de encuestas
                if (!('email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1) && this.$root.profile.email_survey) {
                    menu.push({
                        title: 'Encuestas', 
                        icon: 'assignment_turned_in', 
                        to: "/survey",
                    })
                }

                menu.push({
                    title: 'Mis usuarios',
                    icon: 'group',
                    to: "/ranking-l",
                })

                if('banner' in this.$root.profile.network && this.$root.profile.network.banner.indexOf(this.$root.profile.email) != -1){
                    menu.push({
                        title: 'Causas',
                        icon: 'image', 
                        to: "/banner-s",
                    })
                }

                if(this.$root.profile.network && this.$root.profile.network.routes){

                    menu.push({
                        title: 'Rutas compartidas',
                        icon: 'map', 
                        to: "/routes",
                    })
                }

                // Personalización de red
                if(this.$root.profile.network && this.$root.profile.custom_network){
                    menu.push({
                        title: 'Personalizar red',
                        icon: 'edit', 
                        to: "/custom-network",
                    })
                }

                if('email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1){
                    menu.push({
                        title: 'Usuarios personalización de redes',
                        icon: 'group', 
                        to: "/custom-network-users",
                    })
                }

                if (this.$root.profile.network && this.$root.profile.network.standregister) { 
                    menu.push({
                        title: 'Casas de registro',
                        icon: 'add_home', 
                        to: "/register-stand",
                    })
                }

                if ('email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1) { 
                    menu.push({
                        title: 'Aprobar casas de registro',
                        icon: 'home_filled', 
                        to: "/stand",
                    })
                }
                
                return menu
            },

            profileInfo(){
                return this.$root.profile
            },

            profile(){

                return [
                    {
                        name: this.$root.profile.names,
                        email: this.$root.profile.email
                    }
                ]
            },

            networkFound(){
                return this.$root.networkFound
            },

            photourl(){
                if(this.$root.profile.photo != null && this.$root.profile.photo.length){
                    return config.uploaduser + this.$root.profile.photo
                }
                return ''
            },

            uploadurl(){
                return config.uploadurl
            }
        },
        methods: {
            
            load(link){
                return this[link.load]
            },

            logout(){

                this.$api(this, (xhr) => {
                    xhr.delete('/logout').then((r) => {

                        let data = r.data
                        if(!data.response){
                            this.$router.push({ path : '/login' })
                        } else {
                            this.$removeItem('xtoken', () => this.$router.push({ path : '/login' }))
                        }
                    }).catch(() => {})
                })
            },

            handleClick(link){
                this[link.click](link)
            },

            openRank(item){

                this.loadRank = true
                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-rank', {
                        params: {
                            uuid: this.$root.profile.uuid,
                            key: 'ever'
                        }
                    }).then((r) => {

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.loadRank = false
                        this.$refs.rank.open(Object.assign(this._.cloneDeep(this.$root.profile), itemData))

                    }).catch(() => {
                        this.loadRank = false
                    })
                })
            },

            openGoal(item){

                this.loadGoal = true
                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-goal?uuid='+this.$root.profile.uuid).then((r) => {

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.loadGoal = false
                        this.$refs.goal.open(itemData)

                    }).catch(() => {
                        this.loadGoal = false
                    })
                })
            },

            goNetwork(network){

                if(network.hasOwnProperty('registered') && !network.registered){
                    return
                }

                return window.location.href = network.url
            },

            registerNetwork(network){

                network.loader = true
                let post = {
                    network: network.uuid
                }

                this.$api(this, (xhr) => {
                    return xhr.post('/pre-register', this.$qs.stringify(post)).then(async (r) => {
                        
                        let data = r.data
                        if(data.response){
                            network.registered = true
                            this.goNetwork(network)
                        }
                        network.loader = false
                    }).catch(() => {})
                })
            },

            shareAvatarUrl(network){
                return network.share && (network.share != null && network.share != '') ? this.uploadurl + network.share : ''
            }
        }
    }

</script>