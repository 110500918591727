<template lang="pug">
    div(@click="$router.push({path:'/chat'})")
        v-badge.mx-3.notification-btn(slot="activator" left color="red")
            small(slot="badge") {{ total > 9 ? '10+' : (total < 0 ? '0' : total) }}
            v-icon.cur-p.ml-1(color="white") chat
</template>

<script>

import fn from 'mixins/fn'
export default {
    mixins: [fn],
    data(){
        return {
            total : 0
        }
    },
    async created(){

        this.fetchUnread()

        this.handleChannel('/chat/unread', (dat) => {
            this.total = dat.total
        })
    },
    methods: {

        fetchUnread(){

            this.$api(this, (xhr) => {
                xhr.get('/social/chat/unread').then((r) => {
                    
                    let data = r.data
                    this.total = data.response ? data.data : 0
                }).catch(() => {})
            })
        }
    }
}
</script>