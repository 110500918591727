<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="alertColor"
            v-model="alert")
                | {{ alertText }}
                v-btn(flat, dark, @click.native="alert = false") {{$t('cerrar')}}
        v-dialog(v-model="modal" max-width="600")
            v-form
                v-card
                    v-card-title {{ $t('Asignar insignia') }}
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12)
                                v-select(
                                    v-model="model.badge"
                                    :items='badges',
                                    item-value="value",
                                    item-text="text",
                                    :return-object="true"
                                    :label='$t("Insignia")', 
                                    append-icon="keyboard_arrow_down", 
                                )
                                .btns.btns-right
                                    v-btn(color="secondary" small @click="addBadge") {{ $t('Agregar insignia') }}
                            v-flex(xs12)
                                v-list
                                    v-list-tile(v-for="(badge, i) in model.badges" :key="'badge'+i")
                                        v-list-tile-action
                                            v-icon(:color="badge.color") verified
                                        v-list-tile-content {{ badge.text }}
                                        v-list-tile-action
                                            v-icon(@click="model.badges.splice(i, 1)") delete
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click.native="modal = false") {{ $t('Cancelar') }}
                            v-btn(color="primary" large @click.native="save" :loading="loader") {{ $t('Guardar') }}
</template>
<script>

    export default {
        data() {
            return {
                modal: false,
                modelSchema: {
                    userid : '',
                    badges : []
                },
                model        : {},
                alert        : false,
                alertText    : '',
                alertColor   : '',
                loader       : false
            }
        },
        computed: {
            badges(){

                let badges = [
                    {
                        text  : this.$t('Nos confirma su WhatsApp'),
                        value : 1,
                        color: 'red'
                    },
                    {
                        text  : this.$t('Guarda Nuestro Contacto'),
                        value : 2,
                        color: 'orange'
                    },
                    {
                        text  : this.$t('Responde a Lista de Difusión'),
                        value : 3,
                        color: 'yellow'
                    },
                    {
                        text  : this.$t('Cumple Reto Propuesto'),
                        value : 4,
                        color: 'green'
                    }
                ]
                return badges
            }
        },
        methods: {

            open(userid, badges){

                this.model = this._.cloneDeep(this.modelSchema)
                this.loader = false

                this.modal = true
                this.model.userid = userid
                this.model.badges = badges
            },
            save(){

                this.loader = true
                this.$api(this, (xhr) => {
                    xhr.post('/badge', this.$qs.stringify({
                        badges: JSON.stringify(this.model.badges),
                        userid: this.model.userid
                    })).then((r) => {

                        let data = r.data
                        this.loader = false
                        this.alert  = true
                            
                        if(data.response){

                            this.alertColor = 'success'
                            this.alertText  = this.$t(data.message)
                            
                            this.modal = false
                            this.$emit('success')
                        }else{
                            this.alertColor = 'error'
                            this.alertText  = this.$t(data.message)
                        }
                    }).catch(() => {})
                })
            },
            addBadge(){
                
                let exists = this.model.badges.some(s => s.value == this.model.badge.value)
                if(!exists){
                    this.model.badges.push(this.model.badge)
                }

                this.model.badge = {}
            }
        }
    }

</script>