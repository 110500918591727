<template lang="pug">
    div
        v-dialog(v-model="chart" fullscreen)
            v-card(v-if="chart")
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="chart=false")
                            v-icon close
                    h3.full-width(v-if="!parents") {{ $t('Distribución de referidos') }}
                    h3.full-width(v-else) {{ $t('Distribución genealógica') }}
                    h4.full-width
                        v-avatar.mr-3(:size="48")
                            img(v-if="photoUrl(genesisNode.photo).length" :src="photoUrl(genesisNode.photo)")
                            v-gravatar(v-else-if="Object.keys(genesisNode).length" :email="genesisNode.email")
                        | {{genesisNode.names || genesisNode.name}}
                        span(v-if="$root.profile.type == 0") - {{genesisNode.phone}}
                v-card-text.pt-2
                    template(v-if="!loadingCurrentNode && !parents && Object.keys(currentNode).length")
                        v-layout.mt-2.mb-2(row wrap)
                            v-flex.pa-0(sm12)
                                v-btn.primary.mx-0.mt-0.subchart-btn(:loading="loadRank" small depressed @click="fetchDetailRank(currentNode.uuid, currentNode)")
                                    v-icon(left small) star
                                    | {{ $t('Ver clasificaciones') }}
                            v-flex.pa-0(sm12)
                                v-btn.primary.mx-0.mt-0.subchart-btn(small depressed @click="openSubchartFnParents")
                                    v-icon(left small) fingerprint
                                    | {{ $t('Ver distribución genealógica') }}
                            v-flex.pa-0(sm12 v-if="!genesis")
                                v-btn.primary.mx-0.mt-0.subchart-btn(small depressed @click="openSubchartFn")
                                    v-icon(left small) pie_chart
                                    | {{ $t('Ver distribución de referidos') }}
                    v-layout(row wrap)
                        v-flex(xs12 sm3)
                            template(v-if="loadingCurrentNode")
                                v-progress-circular.mx-auto.text-xs-center(small indeterminate color="primary")
                            div.graph-detail(v-else)
                                h4 {{ $t('Posición') }}: 
                                    b(v-if="(currentNode.rank) > 0") {{ (currentNode.rank) }}
                                h4 {{ $t('Nombre') }}:
                                    v-avatar.mx-1(:size="16")
                                        img(v-if="photoUrl(currentNode.photo).length" :src="photoUrl(currentNode.photo)")
                                        v-gravatar(v-else-if="Object.keys(currentNode).length" :email="currentNode.email") 
                                    | {{ currentNode.names || currentNode.name }}
                                h4(v-if="!referral") {{ $t('Correo electrónico') }}: {{ currentNode.email }}
                                h4 {{ $t('Referidos') }}: {{ currentNode.totalshare }}
                                h4 {{ $t('Red') }}: {{ currentNode.total }}
                                h4 {{ $t('Nivel') }}: {{ currentNode.level }}
                                h4(v-if="isAvailable('birthdate')") {{ $t('Edad') }}: {{ currentNode.age }}
                                h4(v-if="isAvailable('gender')") {{ $t('Género') }}: {{ currentNode.gender }}
                                h4(v-if="isAvailable('cityid')") {{ $t('Ciudad') }}: {{ currentNode.city }}
                                h4(v-if="isAvailable('zones')") {{ $t('Zona') }}: {{ currentNode.zone }}
                                h4(v-if="!referral && currentNode.whatsapp.length"): a(target="blank" :href="currentNode.whatsapp") {{ $t('Escribir al WhatsApp') }}
                                v-divider.primary.my-2
                                h3.mb-2.mt-3 {{ $t('Posiciones') }}
                                h4(v-if="isAvailable('countryid')") {{ currentNode.country }}: 
                                    b {{ currentNode.rank_country }}
                                h4(v-if="isAvailable('stateid')") {{ currentNode.state }}: 
                                    b {{ currentNode.rank_state }}
                                h4(v-if="isAvailable('cityid')") {{ currentNode.city }}: 
                                    b {{ currentNode.rank_city }}
                                template(v-if="isAvailable('zones')")
                                    h4(v-for="(zone, i) in currentNode.rank_zones" :key="'zone'+i")
                                        | {{ zone.zone }}: 
                                        b {{ zone.rank }}
                                color-resume(:colors="colors" :type="color_type")
                        v-flex.text-xs-center(xs12 sm9)
                            v-tabs(
                                v-if="!parents"
                                v-model="chartTab"
                                show-arrows)
                                v-tabs-slider
                                v-tab {{ $t('Todos') }}
                                v-tab {{ $t('Estado') }}
                                v-tab {{ $t('Ciudad') }}
                                v-tab {{ $t('Género') }}
                                v-tab {{ $t('Edad') }}
                                v-tab {{ $t('Zona') }}                                
                                v-tab {{ $t('Formularios') }}
                                v-tab {{ $t('Niveles') }}
                                v-tabs-items(v-model="chartTab")
                                    v-tab-item
                                        template(v-if="chartTab == 0")
                                            v-layout.mt-2.mb-2(row wrap)
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                .btns.btns-right
                                                    v-btn(color="primary" small @click="fetchGraph") {{$t('Filtrar')}}
                                                    v-btn(color="secondary" small @click="resetGraph") {{$t('Restablecer')}}
                                            template(v-if="preloadChart")
                                                v-progress-circular(indeterminate color="primary")
                                            template(v-else)
                                                div.chartdiv(v-if="!chartData.nodes.length")
                                                    | {{$t('No se han encontrado datos') }}
                                                div.chartdiv(:id="'chartdiv'+_uid" v-else)
                                    //- Estados
                                    v-tab-item
                                        template(v-if="chartTab == 1")
                                            v-layout.mt-2.mb-2(row wrap)
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                .btns.btns-right
                                                    v-btn(color="primary" small @click="fetchFilterGraph(stateOpts)") {{$t('Filtrar')}}
                                                    v-btn(color="secondary" small @click="resetFilterGraph(stateOpts)") {{$t('Restablecer')}}
                                            div.my-3
                                                template(v-if="preloadChartState")
                                                    v-progress-circular(indeterminate color="primary")
                                                template(v-else)
                                                    div.chartdiv(v-if="!chartStateData.nodes.length")
                                                        | {{$t('No se han encontrado datos') }}
                                                    div.chartdiv(:id="'chartstatediv'+_uid" v-else)
                                    v-tab-item
                                        template(v-if="chartTab == 2")
                                            v-layout.mt-2.mb-2(row wrap)
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                .btns.btns-right
                                                    v-btn(color="primary" small @click="fetchFilterGraph(cityOpts)") {{$t('Filtrar')}}
                                                    v-btn(color="secondary" small @click="resetFilterGraph(cityOpts)") {{$t('Restablecer')}}
                                            div.my-3
                                                template(v-if="preloadChartCity")
                                                    v-progress-circular(indeterminate color="primary")
                                                template(v-else)
                                                    div.chartdiv(v-if="!chartCityData.nodes.length")
                                                        | {{$t('No se han encontrado datos') }}
                                                    div.chartdiv(:id="'chartcitydiv'+_uid" v-else)
                                    v-tab-item
                                        template(v-if="chartTab == 3")
                                            v-layout.mt-2.mb-2(row wrap)
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                .btns.btns-right
                                                    v-btn(color="primary" small @click="fetchFilterGraph(genderOpts)") {{$t('Filtrar')}}
                                                    v-btn(color="secondary" small @click="resetFilterGraph(genderOpts)") {{$t('Restablecer')}}
                                            div.my-3
                                                template(v-if="preloadChartGender")
                                                    v-progress-circular(indeterminate color="primary")
                                                template(v-else)
                                                    div.chartdiv(v-if="!chartGenderData.nodes.length")
                                                        | {{$t('No se han encontrado datos') }}
                                                    div.chartdiv(:id="'chartgenderdiv'+_uid" v-else)
                                    v-tab-item
                                        template(v-if="chartTab == 4")
                                            v-layout.mt-2.mb-2(row wrap)
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                .btns.btns-right
                                                    v-btn(color="primary" small @click="fetchFilterGraph(ageOpts)") {{$t('Filtrar')}}
                                                    v-btn(color="secondary" small @click="resetFilterGraph(ageOpts)") {{$t('Restablecer')}}
                                            div.my-3
                                                template(v-if="preloadChartAge")
                                                    v-progress-circular(indeterminate color="primary")
                                                template(v-else)
                                                    div.chartdiv(v-if="!chartAgeData.nodes.length")
                                                        | {{$t('No se han encontrado datos') }}
                                                    div.chartdiv(:id="'chartagediv'+_uid" v-else)
                                    v-tab-item
                                        template(v-if="chartTab == 5")
                                            v-layout.mt-2.mb-2(row wrap)
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                v-flex(xs12 sm6)
                                                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                            v-spacer
                                                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                .btns.btns-right
                                                    v-btn(color="primary" small @click="fetchFilterGraph(zoneOpts)") {{$t('Filtrar')}}
                                                    v-btn(color="secondary" small @click="resetFilterGraph(zoneOpts)") {{$t('Restablecer')}}
                                            div.my-3
                                                template(v-if="preloadChartZone")
                                                    v-progress-circular(indeterminate color="primary")
                                                template(v-else)
                                                    div.chartdiv(v-if="!chartZoneData.nodes.length")
                                                        | {{$t('No se han encontrado datos') }}
                                                    div.chartdiv(:id="'chartzonediv'+_uid" v-else)
                                    // Formularios
                                    v-tab-item
                                        template(v-if="chartTab == 6")
                                            div.my-3(v-if="preloadFormGraph")
                                                v-progress-circular(indeterminate color="primary")
                                            template(v-else)
                                                v-layout.mt-2.mb-2(row wrap)
                                                    v-flex(xs12 sm6)
                                                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                            v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                v-spacer
                                                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                    v-flex(xs12 sm6)
                                                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                            v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                v-spacer
                                                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                    v-flex(xs12 sm6)
                                                        v-select(
                                                            v-model="filter.graph"
                                                            :items='formgraphs',
                                                            item-value="uuid"
                                                            item-text="label",
                                                            :label='$t("Gráfico formulario personalizado")',
                                                            append-icon="keyboard_arrow_down",
                                                            autocomplete
                                                            :filter="vFilter"
                                                        )
                                                    .btns.btns-right
                                                        v-btn(color="primary" small @click="fetchFilterGraph(formOpts)") {{$t('Filtrar')}}
                                                        v-btn(color="secondary" small @click="resetFilterGraph(formOpts)") {{$t('Restablecer')}}
                                                div.my-3
                                                    template(v-if="preloadChartForm")
                                                        v-progress-circular(indeterminate color="primary")
                                                    template(v-else)
                                                        div.chartdiv(v-if="!chartFormData.nodes.length")
                                                            | {{$t('No se han encontrado datos') }}
                                                        div.chartdiv(:id="'chartformdiv'+_uid" v-else)
                                    // Niveles
                                    v-tab-item
                                        template(v-if="chartTab == 7")
                                            div.my-3(v-if="preloadLevelGraph")
                                                v-progress-circular(indeterminate color="primary")
                                            template(v-else)
                                                v-layout.mt-2.mb-2(row wrap)
                                                    v-flex(xs12 sm6)
                                                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                            v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                v-spacer
                                                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                    v-flex(xs12 sm6)
                                                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                            v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                v-spacer
                                                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                    .btns.btns-right
                                                        v-btn(color="primary" small @click="fetchFilterGraph(formOpts)") {{$t('Filtrar')}}
                                                        v-btn(color="secondary" small @click="resetFilterGraph(formOpts)") {{$t('Restablecer')}}
                                                div.my-3
                                                    template(v-if="preloadChartLevel")
                                                        v-progress-circular(indeterminate color="primary")
                                                    template(v-else)
                                                        div.chartdiv(v-if="!chartLevelData.nodes.length")
                                                            | {{$t('No se han encontrado datos') }}
                                                        div.chartdiv(:id="'chartleveldiv'+_uid" v-else)
                            template(v-else)
                                template(v-if="preloadChart")
                                    v-progress-circular(indeterminate color="primary")
                                template(v-else)
                                    div.chartdiv(v-if="!chartData.nodes.length")
                                        | {{$t('No se han encontrado datos') }}
                                    div.chartdiv(:id="'chartdiv'+_uid" v-else)
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="chart=false") {{ $t('Aceptar') }}
        chart(v-if="openSubchart" ref="chart" :referral="referral" :parents="isParents")
        rank(ref="rank")
</template>

<script>

import config from 'src/config'
import fn from 'mixins/fn'
import ColorResume from 'components/colors/Resume'

export default {
    components: {
        'color-resume' : ColorResume
    },
    mixins: [fn],
    props: {
        referral: Boolean,
        parents : {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            isParents: false,
            openSubchart: false,
            chart: false,
            preloadChart: false,
            loadingCurrentNode: false,
            genesisNode: {},
            currentNode: {},
            genesis: true,
            chartData: {
                nodes: []
            },
            loadRank: false,
            colors: [],
            color_type: 0,
            filterSchema: {
                from     : null,
                to       : null,
                graph    : ''
            },
            filter: {},
            modalDateStart: false,
            modalDateEnd: false,
            uuid: '',
            chartTab: 0,
            preloadChartGender: false,
            chartGenderData: {
                nodes: []
            },
            preloadChartState: false,
            chartStateData: {
                nodes: []
            },
            preloadChartCity: false,
            chartCityData: {
                nodes: []
            },
            preloadChartZone: false,
            chartZoneData: {
                nodes: []
            },
            preloadChartAge: false,
            chartAgeData: {
                nodes: []
            },
            stateOpts: {
                preload : 'preloadChartState', 
                data    : 'chartStateData', 
                chart   : 'chartstatediv', 
                type    : 1
            },
            cityOpts: {
                preload : 'preloadChartCity', 
                data    : 'chartCityData', 
                chart   : 'chartcitydiv', 
                type    : 2
            },
            zoneOpts: {
                preload : 'preloadChartZone', 
                data    : 'chartZoneData', 
                chart   : 'chartzonediv', 
                type    : 5
            },
            genderOpts: {
                preload : 'preloadChartGender', 
                data    : 'chartGenderData', 
                chart   : 'chartgenderdiv', 
                type    : 3
            },
            ageOpts: {
                preload : 'preloadChartAge', 
                data    : 'chartAgeData', 
                chart   : 'chartagediv', 
                type    : 4
            },
            formgraphs: [],
            formOpts: {
                preload : 'preloadChartForm', 
                data    : 'chartFormData', 
                chart   : 'chartformdiv', 
                type    : 6
            },
            preloadChartForm: false,
            chartFormData: {
                nodes: []
            },
            preloadFormGraph: false,

            // Niveles
            preloadLevelGraph: false,
            levelOpts: {
                preload : 'preloadChartLevel', 
                data    : 'chartLevelData', 
                chart   : 'chartleveldiv', 
                type    : 7
            },
            preloadChartLevel: false,
            chartLevelData: {
                nodes: []
            }
        }
    },
    watch: {
        chartTab(){
            
            this.colors     = []
            this.color_type = 0

            if(this.chartTab == 0){
                this.resetGraph()
            }
            if(this.chartTab == 1){
                this.resetFilterGraph(this.stateOpts)
            }
            if(this.chartTab == 2){
                this.resetFilterGraph(this.cityOpts)
            }
            if(this.chartTab == 5){
                this.resetFilterGraph(this.zoneOpts)
            }
            if(this.chartTab == 3){
                this.resetFilterGraph(this.genderOpts)
            }
            if(this.chartTab == 4){
                this.resetFilterGraph(this.ageOpts)
            }
            if(this.chartTab == 6){
                this.fetchFormGraphs()
            }
            if(this.chartTab == 7){
                this.resetFilterGraph(this.levelOpts)
            }
        }
    },
    methods:{
        photoUrl(photo){
            if(photo != null && photo.length){
                return config.uploaduser + photo
            }
            return ''
        },
        fetchDetailRank(uuid, item={}){

            if(Object.keys(item).length){
                item.loadRank = true
            }else{
                this.loadRank = true
            }

            this.$api(this, (xhr) => {
                xhr.get('/report/network/graph/detail-rank', {
                    params: {
                        uuid,
                        key: 'ever'
                    }
                }).then((r) => {

                    let merge = {}
                        if(Object.keys(item).length){
                            item.loadRank = false
                            merge = item
                        }else{
                            this.loadRank = false
                            merge = this.$root.profile
                        }

                    let data = r.data
                    let itemData = this._.cloneDeep(data.data)
                    this.$refs.rank.open(Object.assign(this._.cloneDeep(merge), itemData))

                }).catch(() => {
                    if(Object.keys(item).length){
                        item.loadRank = false
                    }else{
                        this.loadRank = false
                    }
                })
            })
        },
        openSubchartFn(){

            this.isParents = false
            this.openSubchart = true
            this.$nextTick(() => {
                this.$refs.chart.open(this.currentNode)
            })
        },
        openSubchartFnParents(){

            this.isParents = true
            this.openSubchart = true
            this.$nextTick(() => {
                this.$refs.chart.open(this.currentNode)
            })
        },
        open(item){

            this.genesis = true
            this.chart = true

            this.genesisNode      = this._.cloneDeep(item)
            this.genesisNode.zone = this.genesisNode.zones ? this.genesisNode.zones.map((z) => z.division+'-'+z.name).join(', ') : this.genesisNode.zone
            this.currentNode      = this._.cloneDeep(item)
            this.availableRow     = item.available || []
            this.filter           = this._.cloneDeep(this.filterSchema)
            this.uuid             = item.uuid || item.id

            this.colors      = []
            this.chartTab    = 0
            this.color_type  = 0

            if(this.parents){
                this.resetGraph()
            }
        },
        fetchFormGraphs(){

            this.preloadFormGraph = true

            let url = '/network/color-form?uuid='
            this.$api(this, (xhr) => {
                xhr.get(url + this.genesisNode.networkid).then((r) => {

                    let data = r.data
                    this.preloadFormGraph = false
                    this.formgraphs = data.data

                }).catch(() => {
                    this.preloadFormGraph = false
                })
            })
        },
        fetchGraph(){

            this.preloadChart = true

            let url = '/report/network/graph?uuid='
            if(this.parents){
                url = '/report/network/graph/parent?uuid='
            }

            this.$api(this, (xhr) => {
                xhr.get(url + this.uuid, {
                    params: {
                        filter: JSON.stringify(this.filter)
                    }
                }).then((r) => {

                    let data = r.data
                    this.preloadChart = false
                    this.chartData = data.data

                    if(this.chartData.hasOwnProperty('colors')){
                        this.colors = this.chartData.colors
                    }

                    if(data.response){
                        this.$nextTick(() => this.makeChart(this.chartData))
                    }

                }).catch(() => this.preloadChart = false)
            })
        },
        resetGraph(){

            this.filter = this._.cloneDeep(this.filterSchema)
            this.fetchGraph()
        },
        makeChart(data){

            if(!data.nodes.length){
                return
            }

            let chartdiv = document.getElementById('chartdiv'+this._uid)
            let w = chartdiv.offsetWidth
            let h = chartdiv.offsetHeight

            let Graph = ForceGraph()
            (chartdiv)
                .graphData(data)
                .width(w)
                .height(h)
                .nodeId('id')
                .nodeVal('val')
                .nodeLabel('name')
                .nodeAutoColorBy('group')
                .linkSource('source')
                .linkTarget('target')

            if(this.parents){
                Graph.nodeCanvasObject((node, ctx, globalScale) => {

                    const label = node.name
                    const fontSize = 12/globalScale
                    ctx.font = `${fontSize}px Sans-Serif`

                    const textWidth = ctx.measureText(label).width
                    const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2)
                    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)'
                    ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions)
                    ctx.textAlign = 'center'
                    ctx.textBaseline = 'middle'
                    ctx.fillStyle = node.color
                    ctx.fillText(label, node.x, node.y)
                });
            }else{

                Graph.nodeCanvasObject((node, ctx, globalScale, isShadow) => {

                    const padAmount = isShadow / globalScale
                    const r = Math.sqrt(Math.max(0, node.val || 1)) * Graph.nodeRelSize() + padAmount

                    ctx.beginPath()
                    ctx.arc(node.x, node.y, r, 0, 2 * Math.PI, false)

                    ctx.fillStyle = node.color
                    ctx.lineWidth = 1
                    ctx.strokeStyle = 'rgb(193 193 193)'
                    ctx.fill()
                    ctx.stroke()
                })
            }

            Graph.d3Force('link').strength(() => 0.5)
            Graph.onNodeClick((node) => {

                this.loadingCurrentNode = true
                if(node.id == 1){
                    this.genesis = true
                    this.currentNode = this._.cloneDeep(this.genesisNode)
                    this.loadingCurrentNode = false
                    return
                }

                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail?uuid='+node.id).then((r) => {

                        let data = r.data
                        this.currentNode = this._.cloneDeep(data.data)
                        this.availableRow = data.data.available || []
                        this.loadingCurrentNode = false
                        this.genesis = false

                    }).catch(() => {
                        this.currentNode = {}
                        this.loadingCurrentNode = false
                    })
                })
            })
        },
        resetFilterGraph(options){

            this.filter = this._.cloneDeep(this.filterSchema)
            this.fetchFilterGraph(options)
        },
        fetchFilterGraph({preload, data, chart, type}){

            this[preload] = true

            let url = '/report/network/graph?uuid='
            this.$api(this, (xhr) => {
                xhr.get(url + this.uuid + '&type=' + type, {
                    params: {
                        filter: JSON.stringify(this.filter)
                    }
                }).then((r) => {

                    let dat       = r.data
                    this[preload] = false
                    this[data]    = dat.data

                    if(this[data].hasOwnProperty('colors')){
                        this.colors = this[data].colors
                    }

                    if(this[data].hasOwnProperty('type')){
                        this.color_type = parseInt(this[data].type)
                    }

                    if(dat.response){
                        this.$nextTick(() => this.makeFilterChart({data, chart}))
                    }

                }).catch(() => this[preload] = false)
            })
        },
        makeFilterChart({chart, data}){

            if(!this[data].nodes.length){
                return
            }

            let chartdiv = document.getElementById(chart + this._uid)
            let w = chartdiv.offsetWidth
            let h = chartdiv.offsetHeight

            let Graph = ForceGraph()
            (chartdiv)
                .graphData(this[data])
                .width(w)
                .height(h)
                .nodeId('id')
                .nodeVal('val')
                .nodeLabel((n) => {
                    return `<b>${n.label}</b></br> ${n.name}`
                })
                .nodeAutoColorBy('group')
                .linkSource('source')
                .linkTarget('target')

            Graph.nodeCanvasObject((node, ctx, globalScale, isShadow) => {

                const padAmount = isShadow / globalScale
                const r = Math.sqrt(Math.max(0, node.val || 1)) * Graph.nodeRelSize() + padAmount

                ctx.beginPath()
                ctx.arc(node.x, node.y, r, 0, 2 * Math.PI, false)

                ctx.fillStyle = node.color
                ctx.lineWidth = 1
                ctx.strokeStyle = 'rgb(193 193 193)'
                ctx.fill()
                ctx.stroke()
            })

            Graph.d3Force('link').strength(() => 0.5)
            Graph.onNodeClick((node) => {

                this.loadingCurrentNode = true
                if(node.id == 1){
                    this.genesis = true
                    this.currentNode = this._.cloneDeep(this.genesisNode)
                    this.loadingCurrentNode = false
                    return
                }

                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail?uuid='+node.id).then((r) => {

                        let data = r.data
                        this.currentNode = this._.cloneDeep(data.data)
                        this.availableRow = data.data.available || []
                        this.loadingCurrentNode = false
                        this.genesis = false

                    }).catch(() => {
                        this.currentNode = {}
                        this.loadingCurrentNode = false
                    })
                })
            })
        },
        fetchCountry(){

            this.$api(this, (xhr) => {
                xhr.get('/country', {
                    params: {
                        filter: JSON.stringify({
                            status: 1
                        })
                    }
                }).then((r) => {

                    let data = r.data
                    if(data.response){
                        this.countries = data.data
                    }
                }).catch(() => {})
            })
        },
        fetchState(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.filter.countryid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.states = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCity(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.filter.stateid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.cities = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        }
    }
}
</script>