<template lang="pug">
    div
        v-dialog(lazy max-width="700px" v-model="rank")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="rank=false")
                            v-icon close
                    h3.full-width(v-if="$root.profile.uuid == genesisNode.uuid") {{ $t('Mis rankings') }}
                    h3.full-width(v-else) {{ $t('Rankings') }}
                    h5.full-width 
                        v-avatar.mr-3(:size="48")
                            img(v-if="photoUrl(genesisNode.photo).length" :src="photoUrl(genesisNode.photo)")
                            v-gravatar(v-else-if="Object.keys(genesisNode).length" :email="genesisNode.email")
                        | {{genesisNode.names || genesisNode.name}}
                        span(v-if="$root.profile.type == 0") - {{genesisNode.phone}}
                v-card-text.pt-2
                    p.my-2(v-if="$root.profile.uuid == genesisNode.uuid")
                        | {{ $t('Este es tu ranking de amigos invitados a nuestra red, si quieres mejorar tu ranking') }} 
                        a(href="#tab-0" @click.prevent="fetchDetailRank('ever'),activeRank='tab-0'") {{ $t('de siempre') }} 
                        | , 
                        a(href="#tab-1" @click.prevent="fetchDetailRank('year'),activeRank='tab-1'") {{ $t('del año') }}
                        | , 
                        a(href="#tab-2" @click.prevent="fetchDetailRank('month'),activeRank='tab-2'") {{ $t('del mes') }}
                        | , 
                        a(href="#tab-3" @click.prevent="fetchDetailRank('week'),activeRank='tab-3'") {{ $t('de la semana') }}
                        | , 
                        a(href="#tab-4" @click.prevent="fetchDetailRank('day'),activeRank='tab-4'") {{ $t('del día') }}
                        |&nbsp;o 
                        a(href="#tab-5" @click.prevent="fetchDetailRank('hour'),activeRank='tab-5'") {{ $t('de la hora') }}
                        |&nbsp;da 
                        router-link(to="/share" tag="a") {{ $t('clic aquí') }} 
                        | {{ $t('y comparte tu red, te retamos a mejorar tu ranking') }}.
                    v-tabs(v-model="activeRank")
                        v-tab(@click.prevent="fetchDetailRank(header.key)" v-for="(header, k) in headers" :key="k" :href="'#tab-'+k")
                            | {{ $t(header.text) }}
                        v-tab-item(lazy v-for="(item, k) in items" :key="'tab-'+k" :value="'tab-'+k" :id="'tab-'+k")
                            template(v-if="item.preload")
                                h3.text-xs-center.my-3 {{ $t('Cargando') }}...
                            template(v-else)
                                v-layout.rank-table(row wrap)
                                    v-flex(sm6 xs6 v-for="(rank, i) in item.rank" :key="'rank'+i")
                                        .medal
                                            img(v-if="rank.rank == 1", src="/public/img/icons/medalla-1.png")
                                            img(v-else-if="rank.rank == 2", src="/public/img/icons/medalla-2.png")
                                            img(v-else-if="rank.rank == 3", src="/public/img/icons/medalla-3.png")
                                            template(v-else)
                                                img(src="/public/img/icons/medalla-simple.png")
                                                strong {{ rank.rank }}
                                        span.text-truncate: small {{ rank.div }}
                                        span: small {{ $t('Número de referidos') }}: {{ rank.total }}
                                template(v-if="item.fifty_pos")
                                    h3.mt-4 {{$t('Primeras 50 posiciones')}}
                                    v-tabs(v-model="item.tab")
                                        v-tab(:href="'#tab-'+k+'-1'" v-if="isAvailable('countryid')") {{$t('País')}}
                                        v-tab(:href="'#tab-'+k+'-2'" v-if="isAvailable('stateid')") {{$t('Estado/departamento')}}
                                        v-tab(:href="'#tab-'+k+'-3'" v-if="isAvailable('cityid')") {{$t('Ciudad')}}
                                        v-tab-item(lazy :value="'tab-'+k+'-1'" :id="'tab-'+k+'-1'")
                                            v-data-table(
                                                v-if="isAvailable('countryid')"
                                                :headers='headerCountry' 
                                                :items="item.pos.country"
                                                :rows-per-page-items="$root.rowsPageItems"
                                                :rows-per-page-text="$root.rowsPageText"
                                                item-key='uuid'
                                            )
                                                template(slot="no-results")
                                                    | {{$t('No se han encontrado resultados')}}
                                                template(slot="no-data")
                                                    | {{$t('No se han encontrado resultados')}}
                                                template(slot='headers' slot-scope='props')
                                                    tr
                                                        th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                            span.subheading {{ $t(header.text) }}
                                                template(slot='items' slot-scope='props')
                                                    tr
                                                        td {{ props.item.rank }}
                                                        td {{ props.item.names }}
                                                        td {{ props.item.state }}
                                        v-tab-item(lazy :value="'tab-'+k+'-2'" :id="'tab-'+k+'-2'")
                                            v-data-table(
                                                v-if="isAvailable('stateid')"
                                                :headers='headerState' 
                                                :items="item.pos.state"
                                                :rows-per-page-items="$root.rowsPageItems"
                                                :rows-per-page-text="$root.rowsPageText"
                                                item-key='uuid'
                                            )
                                                template(slot="no-results")
                                                    | {{$t('No se han encontrado resultados')}}
                                                template(slot="no-data")
                                                    | {{$t('No se han encontrado resultados')}}
                                                template(slot='headers' slot-scope='props')
                                                    tr
                                                        th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                            span.subheading {{ $t(header.text) }}
                                                template(slot='items' slot-scope='props')
                                                    tr
                                                        td {{ props.item.rank }}
                                                        td {{ props.item.names }}
                                                        td {{ props.item.city }}
                                        v-tab-item(lazy :value="'tab-'+k+'-3'" :id="'tab-'+k+'-3'")
                                            v-data-table(
                                                v-if="isAvailable('cityid')"
                                                :headers='headerCity' 
                                                :items="item.pos.city"
                                                :rows-per-page-items="$root.rowsPageItems"
                                                :rows-per-page-text="$root.rowsPageText"
                                                item-key='uuid'
                                            )
                                                template(slot="no-results")
                                                    | {{$t('No se han encontrado resultados')}}
                                                template(slot="no-data")
                                                    | {{$t('No se han encontrado resultados')}}
                                                template(slot='headers' slot-scope='props')
                                                    tr
                                                        th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                            span.subheading {{ $t(header.text) }}
                                                template(slot='items' slot-scope='props')
                                                    tr
                                                        td {{ props.item.rank }}
                                                        td {{ props.item.names }}
                                                        td {{ props.item.zones_str }}
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="rank=false") {{$t('Aceptar')}}
</template>

<script>

import config from 'src/config'
import fn from 'mixins/fn'

export default {
    mixins: [fn],
    data(){
        return {
            rank: false,
            preloadRank: false,
            loadingCurrentNode: false,
            genesisNode: {},
            currentNode: {},
            genesis: true,
            items: [],
            activeRank: null,
            headerCountry: [
                {
                    text: 'Nº',
                    align: 'center',
                    value: 'rank'
                },
                {
                    text: 'Nombre(s)',
                    align: 'left',
                    value: 'names'
                },
                {
                    text: 'Estado/departamento',
                    align: 'left',
                    value: 'state'
                },
            ],
            headerState: [
                {
                    text: 'Nº',
                    align: 'center',
                    value: 'rank'
                },
                {
                    text: 'Nombre(s)',
                    align: 'left',
                    value: 'names'
                },
                {
                    text: 'Ciudad',
                    align: 'left',
                    value: 'city'
                },
            ],
            headerCity: [
                {
                    text: 'Nº',
                    align: 'center',
                    value: 'rank'
                },
                {
                    text: 'Nombre(s)',
                    align: 'left',
                    value: 'names'
                },
                {
                    text: 'Zonas',
                    align: 'left',
                    value: 'zones_str'
                },
            ]
        }
    },
    computed:{
        headers(){
            
            let m = this.$moment()

            return [
                {
                    text : 'Siempre',
                    key  : 'ever'
                },
                {
                    text : m.format('YYYY'),
                    key  : 'year'
                },
                {
                    text : m.format('MMMM'),
                    key  : 'month'
                },
                {
                    text : 'Semana',
                    key  : 'week'
                },
                {
                    text : m.format('dddd'),
                    key  : 'day'
                },
                {
                    text : m.format('ha'),
                    key  : 'hour'
                }
            ]
        }
    },
    methods:{

        photoUrl(photo){
            if(photo != null && photo.length){
                return config.uploaduser + photo
            }
            return ''
        },
        
        open(item){

            this.rank = true
            
            this.genesisNode = this._.cloneDeep(item)
            this.currentNode = this._.cloneDeep(item)

            this.items = [
                {
                    label : 'Siempre',
                    key   : 'ever',
                    rank  : [
                        {
                            div  : item.country,
                            rank : item.rank_country.rank,
                            total: item.rank_country.total,
                        },
                        {
                            div  : item.state,
                            rank : item.rank_state.rank,
                            total: item.rank_state.total,
                        },
                        {
                            div  : item.city,
                            rank : item.rank_city.rank,
                            total: item.rank_city.total,
                        },
                        ...item.zones.map((zone) => {
                            return {
                                div: zone.name,
                                rank: zone.rank,
                                total: zone.total
                            }
                        })
                    ],
                    pos: item.pos,
                    fifty_pos: item.fifty_pos,
                    tab   : null,
                    preload: false
                },
                {
                    label : 'Año',
                    key   : 'year',
                    rank  : [],
                    pos: [],
                    fifty_pos: true,
                    tab   : null,
                    preload: false
                },
                {
                    label : 'Mes',
                    key   : 'month',
                    rank  : [],
                    pos   : [],
                    fifty_pos: true,
                    tab   : null,
                    preload: false
                },
                {
                    label : 'Semana',
                    key   : 'week',
                    rank  : [],
                    pos: [],
                    fifty_pos: true,
                    tab   : null,
                    preload: false
                },
                {
                    label : 'Día',
                    key   : 'day',
                    rank  : [],
                    pos: [],
                    fifty_pos: true,
                    tab   : null,
                    preload: false
                },
                {
                    label : 'Hora',
                    key   : 'hour',
                    rank  : [],
                    pos: [],
                    fifty_pos: true,
                    tab   : null,
                    preload: false
                }
            ]

            this.$nextTick(() => {
                this.activeRank = 'tab-0'
            })
        },

        fetchDetailRank(key){
            
            let i = this.items.findIndex((i) => i.key == key)
            this.items[i].preload = true

            this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-rank', {
                        params: {
                            uuid: this.genesisNode.uuid,
                            key: key
                        }
                    }).then((r) => {

                        this.items[i].preload = false
                        
                        let data = r.data.data
                        this.items[i].pos = data.pos
                        this.items[i].fifty_pos = data.fifty_pos
                        this.availableRow = data.available || []

                        this.items[i].rank = [
                            {
                                div  : this.genesisNode.country,
                                rank : data.rank_country.rank,
                                total: data.rank_country.total,
                            },
                            {
                                div  : this.genesisNode.state,
                                rank : data.rank_state.rank,
                                total: data.rank_state.total,
                            },
                            {
                                div  : this.genesisNode.city,
                                rank : data.rank_city.rank,
                                total: data.rank_city.total,
                            },
                            ...data.zones.map((zone) => {
                                return {
                                    div: zone.name,
                                    rank: zone.rank,
                                    total: zone.total
                                }
                            })
                        ]

                    }).catch(() => {
                        this.items[i].preload = false
                    })
                })
        }
    }
}
</script>