<template lang="pug">
    div
        v-snackbar(
        :timeout="3000",
        :top="true"
        :right="true"
        :color="notesColor"
        v-model="notesMsj")
            | {{ notesText }}
            v-btn(flat, dark, @click.native="notesMsj = false") {{ $t('cerrar') }}
        v-dialog(persistent lazy max-width="1024px" v-model="note")
            v-card(v-if="note")
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="note=false")
                            v-icon close
                    h3.full-width {{ $t('Mensajes de la red') }}
                    .d-flex
                        v-avatar.mr-3(:size="48")
                            img(v-if="photoUrl(current.photo).length" :src="photoUrl(current.photo)")
                            v-gravatar(v-else-if="Object.keys(current).length" :email="current.email")
                        h5
                            | {{ current.names || current.name}}
                            small.d-block(v-if="rel && rel == 'parent'") {{ $t('Referidor de') }}&nbsp;{{ currentRel.names }}
                            small.d-block(v-else-if="rel && rel == 'child'") {{ $t('Referido de') }}&nbsp;{{ currentRel.names }}
                v-card-text.pt-2
                    template(v-if="loadModal")
                            h3 {{ $t('Cargando...') }}
                    template(v-else)
                        v-layout.mb-3(row wrap)
                            v-flex(xs12 md6)
                                v-text-field.mb-2(multi-line rows="3" label="Mensaje" v-model="comment")
                                .btns.btns-right
                                    v-btn(v-if="rel" :loading="loadingBtn" color="primary" small @click.native="add") {{ $t('Agregar como') }}&nbsp;{{ currentRel.names }}
                                    v-btn(v-else :loading="loadingBtn" color="primary" small @click.native="add") {{ $t('Agregar') }}
                                v-checkbox(v-model="pending" label="Marcar como pendiente")
                                template(v-if="pending")
                                    v-dialog.mb-3(ref='dialogPending' v-model='modalDatePending' lazy full-width width='290px' :return-value.sync='pending_date')
                                        v-text-field(slot='activator' label='Fecha de aviso' v-model='pending_date' readonly)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='pending_date' scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click.stop='modalDatePending = false') {{ $t('Cancelar') }}
                                            v-btn(flat color='primary' @click.stop='$refs.dialogPending.save(pending_date)') {{ $t('OK') }}
                                    .btns.btns-right(v-if="!current.hasOwnProperty('pending') || !Object.keys(current.pending).length")
                                        v-btn(:loading="loadingPending" color="primary" small @click.native="savePending(current)") {{ $t('Guardar') }}
                            v-flex(xs12 md6)
                                h3.mb-2 {{ $t('Datos del usuario') }}
                                div
                                    v-menu
                                        a.primary--text(slot='activator') {{ $t('Ver opciones') }}
                                        v-list.sheet-menu
                                            v-list-tile.white--text(@click.native="$refs.form.open(current)")
                                                v-list-tile-title {{ $t('Ver datos del usuario') }}
                                            v-list-tile.white--text(v-if="(!isEmail && !isComment) || (!isEmail && isComment ? false : true)" @click.native="$refs.crmForm.open({'uuid': current.networkid})")
                                                v-list-tile-title {{ $t('Agregar nueva pregunta') }}
                                            v-list-tile.white--text(v-if="current.referid" @click.native="$refs.notes.open(current.referid, current, 'parent')")
                                                v-list-tile-title {{ $t('Ver referidor') }}
                                            v-list-tile.white--text(@click.native="$refs.notesPos.open(current)")
                                                v-list-tile-title {{ $t('Ver referidos') }}
                                v-layout(row wrap v-if="formList.length")
                                    v-flex(xs12)
                                        v-select(
                                            v-model="crm.formid"
                                            :items='formList',
                                            item-value="uuid",
                                            item-text="placeholder",
                                            :return-object="true"
                                            :label='$t("Seleccione una pregunta")', 
                                            append-icon="keyboard_arrow_down"
                                            clearable
                                            :loading="loadingForm"
                                        )
                                    template(v-if="crm.formid")
                                        v-flex(xs12)
                                            edit-form-data(
                                                :custom-form="customFormList"
                                                v-model="crm_values"
                                                :required-option="false"
                                                :enable-depends="false"
                                            )
                                        .btns.btns-right
                                            v-btn(:loading="loadingBtnForm" color="primary" small @click.native="saveForm") {{ $t('Guardar') }}
                        .btns.btns-right(v-if="current.hasOwnProperty('pending') && Object.keys(current.pending).length")
                            v-btn(:loading="loadingPending" color="primary" small @click.native="savePending(current)") {{ $t('Guardar') }}
                        v-divider.mb-2
                        v-tooltip(top)
                            template(slot="activator")
                                v-btn.blue.white--text.mr-0(style="font-size:70%;width:30px;height:30px;border-radius:100px;" fab small depressed) {{ total_mine }}
                            span {{ $t('Mis respuestas') }}
                        v-tooltip(top)
                            template(slot="activator")
                                v-btn.red.white--text.ml-1(style="font-size:70%;width:30px;height:30px;border-radius:100px;" fab small depressed) {{ total_other }}
                            span {{ $t('Respuestas de otros') }}
                        div.mt-2(style="max-height:300px;overflow:hidden;overflow-y:scroll;")
                            template(v-if="preload")
                                h3 {{ $t('Cargando') }}...
                            template(v-else)
                                template(v-if="notes.length")
                                    div.mt-4.px-2(v-for="note in notes")
                                        div(style="display:flex")
                                            .d-flex
                                                v-avatar.mr-2(:size="20")
                                                    img(v-if="photoUrl(note.photo).length" :src="photoUrl(note.photo)")
                                                    v-gravatar(v-else-if="Object.keys(note).length" :email="note.email")
                                                h5
                                                    | {{ note.names }}
                                                    a.ml-2(@click="$refs.delete.open(note)" v-if="note.authorid == profile.uuid") {{ $t('Eliminar') }}
                                                    small.d-block(v-if="note.rel.rel && note.rel.rel == 'parent'") {{ $t('Esto es lo que dice su referido H.H') }}&nbsp;{{ note.rel.relnames }}
                                                    small.d-block(v-else-if="note.rel.rel && note.rel.rel == 'child'") {{ $t('Esto es lo que dice su referidor P.P') }}&nbsp;{{ note.rel.relnames }}
                                        p.pa-0.ma-0.mt-2 {{ note.comment }}
                                        small {{ $t('Fecha de creación') }}: {{ note.datecreated }}
                                        v-divider.my-2.x1.primary
                                    infinite-loading(:infinite="infinite" ref="infinite")
                                .not-found(v-else)
                                    h3 {{ $t('No se encontraron conversaciones') }}
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="note=false") {{$t('Aceptar')}}
        confirm(v-if="note" ref="delete" @action="deleteNote" :title="$t('Eliminar mensaje')" :message="$t('¿Estás seguro de eliminar este mensaje?')")
        crm-form(v-if="note" ref="crmForm" @success="reFetchFormList")
        form-data(v-if="note" ref="form")
        notes(v-if="note" ref="notes")
        notes-pos(v-if="note" ref="notesPos")
</template>

<script>

import config from 'src/config'
import CrmForm from 'components/crm/Form'
import EditFormData from 'components/form/FormData'
import NotesPos from 'components/NotesPos'

export default {
    data(){
        return {
            loadModal: false,
            note: false,
            filterSchema: {
                from : null,
                to   : null
            },
            comment: '',
            filter: {},
            current: {},
            notesMsj  : false,
            notesColor: '',
            notesText : '',
            loadingBtn: false,
            limit : this.$root.limit,
            offset: 0,
            notes: [],
            preload: false,
            total_mine  : 0,
            total_other : 0,
            
            // Pendiente
            pending: false,
            modalDatePending: false,
            pending_date: null,
            loadingPending: false,
            formList: [],
            crmSchema: {
                formid: ''
            },
            crm: {},
            crm_values: {},
            loadingBtnForm: false,
            loadingForm: false,
            rel: '',
            currentRel: {}
        }
    },
    computed: {
        profile(){
            return this.$root.profile
        },
        customFormList(){
            if (this.crm.hasOwnProperty('formid') && this.crm.formid) {
                return [this.crm.formid]
            }

            return []
        },
        isEmail() {
            return this.$root.profile.network != undefined && 'email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1
        },
        isComment() {
            return this.$root.profile.network != undefined && 'email_comment' in this.$root.profile.network && this.$root.profile.network.email_comment.indexOf(this.$root.profile.email) != -1
        }
    },
    watch: {
        crm: {
            deep: true,
            handler() {
                this.crm_values = {}          
            }
        }
    },
    methods:{

        photoUrl(photo){
            if(photo != null && photo.length){
                return config.uploaduser + photo
            }
            return ''
        },

        async open(item, currentRel = {}, rel = '') {

            this.note = true
            this.rel = rel
            this.currentRel = {}

            if (Object.keys(currentRel).length) {
                this.currentRel = this._.cloneDeep(currentRel)  
                await this.fetchCurrent(item)
            } else {
                this.current = this._.cloneDeep(item)   
            }

            this.formList = []
            this.crm = this._.cloneDeep(this.crmSchema)
            this.crm_values = {}

            this.comment=''
            this.pending = false
            this.pending_date = null
            
            this.fetch()
            this.fetchPending()

            this.loadModal = true   
            await this.fetchFormList()
            this.loadModal = false
        },

        fetch(infinite=false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.notes = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    userid: this.current.uuid,
                    filter: JSON.stringify(this.filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get('/notes', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        this.total_mine = data.total_mine
                        this.total_other = data.total_other

                        if(infinite){
                            if(data.response){
                                this.notes = this.notes.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.notes = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
        },

        infinite(){
            setTimeout(() => this.fetch(true) , 500)
        },

        fetchCurrent(uuid) {
            return new Promise((resolve) => {
                this.loadModal = true
                this.$api(this, (xhr) => {
                    xhr.get('/report/network/user', {
                        params: {
                            uuid
                        }
                    }).then((r) => {
                        let data = r.data
                        if (data.response) {
                            this.current = this._.cloneDeep(data.data)
                        }
                        this.loadModal = false
                        resolve()
                    }).catch(() => {
                        this.loadModal = false
                        resolve()
                    })
                })
            })
        },

        add(){

            let post = {
                comment : this.comment,
                userid: this.current.uuid
            }

            if (this.currentRel.hasOwnProperty('uuid')) {
                post.rel = this.rel
                post.relid = this.currentRel.uuid
            }

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/notes', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.notesMsj = true

                    if(data.response){
                        this.notesColor = 'success'
                        this.notesText = data.message
                        this.fetch()
                        this.comment = ''
                    }else{
                        this.notesColor = 'error'
                        this.notesText = data.message
                    }
                }).catch(() => {})
            })
        },

        deleteNote({uuid}){

            this.$refs.delete.$emit('playLoader')
            this.$api(this, (xhr) => {
                xhr.delete('/notes?uuid=' + uuid).then((r) => {
                    
                    this.$refs.delete.$emit('stopLoader')
                    this.notesMsj = true

                    let data = r.data

                    if(data.response){
                        this.notesColor = 'success'
                        this.notesText = data.message
                        this.$refs.delete.close()
                        this.fetch()
                    }else{
                        this.notesColor = 'error'
                        this.notesText = data.message
                    }
                }).catch(() => {})
            })
        },

        savePending(current){

            let post = {
                pending : this.pending ? 1: 0,
                userid  : this.current.uuid,
                pending_date: this.pending_date
            }

            this.loadingPending = true
            this.$api(this, (xhr) => {
                xhr.post('/notes/pending', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingPending = false
                    this.notesMsj = true

                    if(data.response){
                        
                        this.notesColor = 'success'
                        this.notesText = data.message
                        this.fetch()
                        this.comment = ''
                        this.$emit('success')

                    }else{
                        this.notesColor = 'error'
                        this.notesText = data.message
                    }
                }).catch(() => {})
            })
        },

        fetchPending(){

            this.$api(this, (xhr) => {
                xhr.get('/notes/pending', {
                    params: { uuid: this.current.uuid }
                }).then((r) => {

                    let data = r.data
                    if(data.response){

                        let pending = data.data
                        pending.pending = pending.pending == 1

                        this.pending = pending.pending
                        this.pending_date = this.$moment(pending.pending_date).format('YYYY-MM-DD')
                    }

                }).catch(() => {})
            })
        },

        fetchFormList() {
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/crm/form/list', {
                        params: {
                            networkid: this.current.networkid,
                            userid: this.current.uuid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let formList = data.data                            
                            this.formList = this._.cloneDeep(formList)
                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })  
        },

        async reFetchFormList() {
            this.loadingForm = true
            await this.fetchFormList()
            this.loadingForm = false
        },

        saveForm() {

            if (!Object.keys(this.crm_values).length) {
                return
            }

            let post = {}
            post.networkid = this.current.networkid
            post.custom_id = Object.keys(this.crm_values)[0]
            post.custom_value = Object.values(this.crm_values)[0]
            post.user_id = this.current.uuid

            if (Array.isArray(post.custom_value)) {
                post.custom_value = JSON.stringify(post.custom_value)
            }

            if (typeof post.custom_value == 'object') {
                post.custom_value = post.custom_value.option
            }

            this.loadingBtnForm = true
            this.$api(this, (xhr) => {
                xhr.post('/crm/form/user', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtnForm = false
                    this.notesMsj = true

                    if(data.response){
                        
                        this.notesColor = 'success'
                        this.notesText = data.message
                        this.reFetchFormList()

                        this.crm_values = {}
                        this.crm = this._.cloneDeep(this.crmSchema)

                    }else{
                        this.notesColor = 'error'
                        this.notesText = data.message
                    }
                }).catch(() => {})
            })
        }
    },
    components: {
        CrmForm,
        EditFormData,
        NotesPos
    }
}

</script>