<template lang="pug">
    .star-rating
        label.star-rating__star(v-for="(rating, i) in ratings" :key="i" :class="{'is-selected': ((value >= rating) && value != null)}" v-on:click="set(rating)" v-on:mouseover="star_over(rating)" v-on:mouseout="star_out")
            input.star-rating.star-rating__checkbox(type="radio" :value="rating" v-model="value") 
            v-icon(size="20px") star
</template>

<script>
    export default {
        data() {
            return {
                ratings: [1, 2, 3, 4, 5],
                temp_value: null,
                value: null
            }
        },
        props: {
            score: Number,
        },
        mounted(){
            this.value = this.score
        },
        methods: {

            star_over(index){
                this.temp_value = this.value
                return this.value = index
            },

            star_out(){
                return this.value = this.temp_value
            },

            set(value){

                this.temp_value = value
                this.value = value

                this.$emit('set', this.value)
            }
        }
    }
</script>