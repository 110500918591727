<template lang="pug">
    v-menu(
        v-model="notificationbar"
        attach='.notification-bar'
        fixed
        full-width
        lazy 
        :close-on-content-click="false" 
        max-height="400" 
        max-width="600"
        offset-y
        offset-x
        nudge-top="24"
        content-class="notification-menu"
        auto
        right
    )
        v-badge.mx-3.notification-btn(slot="activator" left color="red")
            small(v-if="!loadTotal" slot="badge") {{ total > 9 ? '10+' : (total < 0 ? '0' : total) }}
            v-icon.cur-p.ml-1(color="white" @click="fetchNotification(false)") group_add
        div.white.pa-2.notification
            div
                h4 {{ $t('Solicitudes de amistad') }}
            v-divider.x1.primary
            v-progress-linear(v-if="loadNotification" height="5" indeterminate color="primary")
            .notification-list(v-else-if="notifications.length")
                v-list.pt-2(light)
                    v-list-tile(v-for="(notification, i) in notifications" :key="'not'+i")
                        v-list-tile-content: small 
                            span(v-html="notification.render")
                        v-list-tile-action
                            v-icon(color="primary" right v-if="!notification.load" @click="state(1, i, notification, 'load')") check
                            v-progress-circular(v-else-if="notification.load" indeterminate :size="16" :width="1" color="primary")
                            v-icon(v-if="!notification.loadDel" color="primary" right @click="state(0, i, notification, 'loadDel')") delete
                            v-progress-circular(v-else indeterminate :size="16" :width="1" color="primary")
                infinite-loading(:infinite="infinite" ref="infinite")
            small.d-block.py-3.text-xs-center(v-if="!loadNotification && !notifications.length") {{ $t('No se han encontrado solicitudes de amistad') }}
</template>

<script>

import fn from 'mixins/fn'
export default {
    mixins: [fn],
    data(){
        return {
            loadNotification  : false,
            loadTotal         : false,
            notifications     : [],
            limit             : this.$root.limit,
            offset            : 0,
            notificationbar   : false,
            total             : 0
        }
    },
    async created(){

        this.$root.$on('accept_friend_request_total', (data) => {
            
            let idx = this.notifications.findIndex((f) => f.uuid == data.requestid)

            this.notifications.splice(idx, 1)
            this.total  = (this.total - 1) < 0 ? 0 : (this.total - 1)
            this.offset = (this.offset - 1) < 0 ? 0 : (this.offset - 1)
        })

        this.fetchTotal()

        this.handleChannel('/friend_request', (data) => {
            
            this.offset += 1
            this.total += 1

            this.notifications.unshift(data)
            this.$refs.infinite && this.$refs.infinite.reset()
        })

        this.handleChannel('/friend_request/state', (data) => {

            if(data.hasOwnProperty('owner') && this.$root.profile.uuid == data.owner){
                
                // Solicitud cancelada
                if(data.state == 0){

                    let idx = this.notifications.findIndex((f) => f.uuid == data.requestid)
                    
                    this.notifications.splice(idx, 1)
                    this.total  = (this.total - 1) < 0 ? 0 : (this.total - 1)
                    this.offset = (this.offset - 1) < 0 ? 0 : (this.offset - 1)
                }
            }
        })
    },
    methods: {

        state(state, i, notification, loader){

            notification[loader] = true
            this.$api(this, (xhr) => {
                xhr.post('/social/friend_request/state', this.$qs.stringify({
                    state,
                    uuid: notification.uuid
                })).then((r) => {
                    
                    let data = r.data
                    notification[loader] = false

                    if(data.response){
                        
                        this.notifications.splice(i, 1)
                        this.total  = (this.total - 1) < 0 ? 0 : (this.total - 1)
                        this.offset = (this.offset - 1) < 0 ? 0 : (this.offset - 1)
                    }

                }).catch(() => notification[loader] = false)
            })
        },

        fetchNotification(infinite=false){

            setTimeout(() => {

                if(!this.notificationbar){
                    return
                }

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.loadNotification = true
                    this.notifications = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                }

                this.$api(this, (xhr) => {
                    xhr.get('/social/friend_request', { params: filter }).then((r) => {

                        if(!infinite) this.loadNotification = false
                        let data = r.data

                        data.data = data.data.map((dat) => {
                            dat.load = false
                            dat.loadDel = false
                            return dat
                        })

                        if(infinite){
                            if(data.response){
                                this.notifications = this.notifications.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.notifications = (data.response ? data.data : [])
                        }

                    }).catch(() => {
                        this.loadNotification = false
                    })
                })
            }, 1)
        },

        fetchTotal(){

            this.$api(this, (xhr) => {
                xhr.get('/social/friend_request/total').then((r) => {
                    
                    let data = r.data
                    this.loadTotal = false
                    this.total = data.data
                }).catch(() => {})
            })
        },

        infinite(){
            setTimeout(() => this.fetchNotification(true) , 500)
        }
    }
}
</script>